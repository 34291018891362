import React from "react";
import Footer from "../../common/components/footer";
import ValueeHeader from "../components/value/header";
import ValueBody from "../components/value/body";
import ScrollToTop from "../../common/components/scroll-button";
import RangeCategories, {
  RangeCategoriesForMobile,
} from "../components/range-body-components/range-categories";

export default function Value() {
  return (
    <>
      <ScrollToTop />
      <ValueeHeader />
      <RangeCategoriesForMobile />
      <>
        <div className="container ">
          <div className="row  d-flex">
            <div className="col-xl-4 col-12 d-none d-lg-block">
              <RangeCategories />
            </div>
            <div className="col-xl-8 col-12">
              <ValueBody />
            </div>
          </div>
        </div>
      </>
      <Footer />
    </>
  );
}
