import React, { useCallback, useState } from "react";
import { BiSearchAlt2 } from "react-icons/bi";
import Navbar from "../navbar";
import SearchBox from "../search-box";
import styles from "./style.module.css";

export default function HeaderComponent() {
  const [fix, setFix] = useState(false);

  function setFixed() {
    if (window.scrollY >= 100) {
      setFix(true);
    } else {
      setFix(false);
    }
  }

  window.addEventListener("scroll", setFixed);

  const [search, setSearch] = useState(false);

  const fixSearch = useCallback(() => {
    setSearch(!search);
    /* setLanguage(false); */
  }, [search]);

  /*  const [language, setLanguage] = useState(false); */

  /*   const toggleLanguage = useCallback(() => {
    setLanguage(!language);
    setSearch(false);
  }, [language]); */

  /*  const onChangeValue = (languageText: any) => {
    console.log(languageText);
    toggleLanguage();
  }; */

  return (
    <>
      <div
        className={fix ? styles.headerFixedContainer : styles.headerContainer}
      >
        <div className={styles.imgContainer}>
          <a href="/">
            <img
              alt=""
              src={require("../../../assets/icons/FLAMEFIELDLogo-03.webp")}
              className={styles.img}
            />
          </a>
        </div>
        <div
          className={styles.navbar}
          onClick={() => {
            /*   setLanguage(false); */
            setSearch(false);
          }}
        >
          <Navbar />
        </div>

        <div className={styles.searchBar}>
          <a
            href="https://b2b.flamefield.com/"
            rel="nofollow"
            target={"_blank"}
            className={styles.btbText}
          >
            <p>B2B Login</p>
          </a>

          {/* <p className={styles.languageText} onClick={toggleLanguage}>
            Language
          </p> */}
          <BiSearchAlt2 className={styles.searchIcon} onClick={fixSearch} />
        </div>
      </div>
      {/*  {language && (
        <div className={styles.languageFullContainer}>
          <div className={styles.languageSelector}>
            <div className={styles.formGroup}>
              <ul>
                <li
                  onClick={() => onChangeValue("Türkçe")}
                  className={styles.languageField}
                >
                  TR
                </li>
                <li
                  onClick={() => onChangeValue("English")}
                  className={styles.languageField}
                >
                  EN
                </li>
                <li
                  onClick={() => onChangeValue("Deutsch")}
                  className={styles.languageField}
                >
                  DE
                </li>
              </ul>
            </div>
          </div>
          <div
            className={styles.languageSelectorEmpty}
            onClick={toggleLanguage}
          ></div>
        </div>
      )} */}

      <div
        className={
          search ? styles.searchBoxContainerActive : styles.searchBoxContainer
        }
      >
        <SearchBox />
        <div
          onClick={fixSearch}
          className={
            search ? styles.blankContainerActive : styles.blankContainer
          }
        ></div>
      </div>
    </>
  );
}
