import React from "react";
import RangeProduct from "../range-body-components/range-product";

import {
  vintage,
  zen,
  pinstirpe,
  carre,
  ink,
  tiffany,
  royaleEcru,
  royaleOnyx,
  pebble,
} from "../premium/body";

export default function AllPremiumBody() {
  return (
    <>
      <RangeProduct
        productImg={tiffany.img}
        productImgProduct={tiffany.imgProduct}
        productName={tiffany.name}
        amount={tiffany.amount}
        stockCode={tiffany.stock}
        productAciklma={tiffany.text}
        accImage={tiffany.accImg}
        accImageProduct={tiffany.accImgProduct}
        accName={tiffany.accName}
        accProductName={tiffany.accProductName}
        accAmount={tiffany.accAmount}
        accStock={tiffany.accStock}
      />

      <RangeProduct
        productImg={royaleEcru.img}
        productImgProduct={royaleEcru.imgProduct}
        productName={royaleEcru.name}
        amount={royaleEcru.amount}
        stockCode={royaleEcru.stock}
        productAciklma={royaleEcru.text}
        accImage={royaleEcru.accImg}
        accImageProduct={royaleEcru.accImgProduct}
        accName={royaleEcru.accName}
        accProductName={royaleEcru.accProductName}
        accAmount={royaleEcru.accAmount}
        accStock={royaleEcru.accStock}
      />

      <RangeProduct
        productImg={royaleOnyx.img}
        productImgProduct={royaleOnyx.imgProduct}
        productName={royaleOnyx.name}
        amount={royaleOnyx.amount}
        stockCode={royaleOnyx.stock}
        productAciklma={royaleOnyx.text}
        accImage={royaleOnyx.accImg}
        accImageProduct={royaleOnyx.accImgProduct}
        accName={royaleOnyx.accName}
        accProductName={royaleOnyx.accProductName}
        accAmount={royaleOnyx.accAmount}
        accStock={royaleOnyx.accStock}
      />

      <RangeProduct
        productImg={pebble.img}
        productImgProduct={pebble.imgProduct}
        productName={pebble.name}
        amount={pebble.amount}
        stockCode={pebble.stock}
        productAciklma={pebble.text}
      />
      <RangeProduct
        productImg={vintage.img}
        productImgProduct={vintage.imgProduct}
        productName={vintage.name}
        amount={vintage.amount}
        stockCode={vintage.allStock}
        productAciklma={vintage.text}
        accImage={vintage.accImg}
        accImageProduct={vintage.accImgProduct}
        accName={vintage.accName}
        accProductName={vintage.accProductName}
      />

      <RangeProduct
        productImg={zen.img}
        productImgProduct={zen.imgProduct}
        productName={zen.name}
        amount={zen.amount}
        amount2={zen.amount2}
        amount3={zen.amount3}
        amount4={zen.amount4}
        stockCode={zen.stock}
        stockCode2={zen.stock2}
        stockCode3={zen.stock3}
        stockCode4={zen.stock4}
        productAciklma={zen.text}
      />

      <RangeProduct
        productImg={pinstirpe.img}
        productImgProduct={pinstirpe.imgProduct}
        productName={pinstirpe.name}
        amount={pinstirpe.amount}
        amount2={pinstirpe.amount2}
        amount3={pinstirpe.amount3}
        amount4={pinstirpe.amount4}
        stockCode={pinstirpe.stock}
        stockCode2={pinstirpe.stock2}
        stockCode3={pinstirpe.stock3}
        stockCode4={pinstirpe.stock4}
        productAciklma={pinstirpe.text}
        accImage={pinstirpe.accImg}
        accImageProduct={pinstirpe.accImgProduct}
        accName={pinstirpe.accName}
        accProductName={pinstirpe.accProductName}
        accAmount={pinstirpe.accAmount}
        accStock={pinstirpe.accStock}
      />

      <RangeProduct
        productImg={carre.img}
        productImgProduct={carre.imgProduct}
        productName={carre.name}
        amount={carre.amount}
        stockCode={carre.stock}
        productAciklma={carre.text}
      />

      <RangeProduct
        productImg={ink.img}
        productImgProduct={ink.imgProduct}
        productName={ink.name}
        amount={ink.amount}
        amount2={ink.amount2}
        amount3={ink.amount3}
        amount4={ink.amount4}
        stockCode={ink.stock}
        stockCode2={ink.stock2}
        stockCode3={ink.stock3}
        stockCode4={ink.stock4}
        productAciklma={ink.text}
        accImage={ink.accImg}
        accImageProduct={ink.accImgProduct}
        accName={ink.accName}
        accProductName={ink.accProductName}
        accAmount={ink.accAmount}
        accStock={ink.accStock}
        accImage2={ink.accImg2}
        accImageProduct2={ink.accImg2Product}
        accName2={ink.accName2}
        accProductName2={ink.accProductName2}
        accAmount2={ink.accAmount2}
        accStock2={ink.accStock2}
      />
    </>
  );
}
