import React from "react";
import AllPremiumBody from "../../all-products/AllPremiumBody";

export const vintage = {
  name: "Vintage Beige - Vintage Blue",
  img: require("../../../../assets/icons/premium/thumbnail/vintage.jpg"),
  imgProduct: require("../../../../assets/icons/premium/products/vintage.jpg"),
  text: "This melamine dinnerware is very elegant with a style that is both romantic and “chic”. The design brings to mind the French Riviera, good red wine and delicious French food.",
  amount: "Vintage Beige 12pc Set,",
  amount2: "Vintage Blue 12pc Set, ",
  stock: "VIN0112(BEI)",
  allStock: "VIN0112(BEI)-(BLU)",
  stock2: "VIN0112(BLU)",
  accImg: require("../../../../assets/icons/premium/thumbnail/vintage-acc.jpg"),
  accImgProduct: require("../../../../assets/icons/premium/products/vintage-acc.jpg"),
  accName: "Suggested Accessories",
  accProductName: "Crystal Wine Glass",
};
export const zen = {
  name: "Zen",
  img: require("../../../../assets/icons/premium/thumbnail/zen.jpg"),
  imgProduct: require("../../../../assets/icons/premium/products/zen.jpg"),
  text: "An understated elegance with distinctive minimalist design on square plates and bowls.",
  amount: "16pc Set,",
  amount2: "Dinner Plate 48pc,",
  amount3: "Side Plate 48pc,",
  amount4: "Bowl 48pc,",
  amount5: "Mug 48pc,",
  stock: "ZN0116",
  stock2: "ZN657",
  stock3: "ZN656",
  stock4: "ZN672",
  stock5: "SLA423",
};
export const pinstirpe = {
  name: "Navy Pinstripe",
  img: require("../../../../assets/icons/premium/thumbnail/pinstripe.jpg"),
  imgProduct: require("../../../../assets/icons/premium/products/pinstripe.jpg"),
  text: "A strong quality, lightweight two colour set. Elegantly designed for Marine use..",
  className: "luna",
  amount: "12pc Set,",
  amount2: "Dinner Plate 48pc,",
  amount3: "Side Plate 48pc,",
  amount4: "Bowl 48pc,",
  amount5: "Mug 48pc,",
  stock: "NP0116",
  stock2: "NPS408",
  stock3: "NPS301",
  stock4: "NPS101",
  stock5: "NPS423",
  accImg: require("../../../../assets/icons/premium/thumbnail/pinstripe-acc.jpg"),
  accImgProduct: require("../../../../assets/icons/premium/products/pinstripe-acc.jpg"),
  accName: "Matching Accessories",
  accProductName: "Navy Pinstripe Salad Bowl",
  accAmount: "4pk,",
  accStock: "NPS311",
};
export const carre = {
  name: "Carré",
  img: require("../../../../assets/icons/premium/thumbnail/carre.jpg"),
  imgProduct: require("../../../../assets/icons/premium/products/carre.jpg"),
  text: "A chic geometric border design, with complementing square shaped plates and bowls in premium quality.",
  amount: "16pc Set,",
  stock: "CA0116",
};
export const ink = {
  name: "Ink",
  img: require("../../../../assets/icons/premium/thumbnail/ink.jpg"),
  imgProduct: require("../../../../assets/icons/premium/products/ink.jpg"),
  text: "Artisticaly inspired, a bold and eye catching statement piece.",
  amount: "16pc Set,",
  amount2: "Dinner Plate 48pc,",
  amount3: "Side Plate 48pc,",
  amount4: "Bowl 48pc,",
  amount5: "Mug 48pc,",
  stock: "INK0116",
  stock2: "INK408",
  stock3: "INK301",
  stock4: "INK101",
  stock5: "INK423",
  accImg: require("../../../../assets/icons/premium/thumbnail/ink-acc.jpg"),
  accImgProduct: require("../../../../assets/icons/premium/products/ink-acc.jpg"),
  accName: "Matching Accessories",
  accProductName: "Ink Salad Bowl",
  accAmount: "24pc,",
  accStock: "INK312",
  accImg2: require("../../../../assets/icons/premium/thumbnail/ink-acc2.jpg"),
  accImg2Product: require("../../../../assets/icons/premium/products/ink-acc2.jpg"),
  accName2: "Matching Accessories",
  accProductName2: "Ink Rectangular Platter",
  accAmount2: "24pc,",
  accStock2: " INK354",
};

export const tiffany = {
  name: "Tiffany",
  img: require("../../../../assets/icons/premium/thumbnail/tiffany.jpg"),
  imgProduct: require("../../../../assets/icons/premium/products/tiffany.jpg"),
  text: "A premium quality dinner set in a vintage style design and glossy finish.",
  amount: "12pc Set",
  stock: "TIF0112",
  accImg: require("../../../../assets/icons/premium/thumbnail/tiffany-acce.png"),
  accImgProduct: require("../../../../assets/icons/premium/products/tiffany-acce.png"),
  accName: "Matching Accessories",
  accProductName: "Stackable Mugs",
  accAmount: "4 pc",
  accStock: "TIF423",
};

export const royaleEcru = {
  name: "Royale Ecru",
  img: require("../../../../assets/icons/premium/thumbnail/royale.jpg"),
  imgProduct: require("../../../../assets/icons/premium/products/royale.jpg"),
  text: "A sophisticated design in white with a beige edge",
  amount: "12pc Set,",
  stock: "RYE0112",
  accImg: require("../../../../assets/icons/premium/thumbnail/royale-acc.jpg"),
  accImgProduct: require("../../../../assets/icons/premium/products/royale-acc.jpg"),
  accName: "Suggested Accessories",
  accProductName: "Clear Acrylic Wine Goblet",
  accAmount: "",
  accStock: "",
};
export const royaleOnyx = {
  name: "Royale Onyx",
  img: require("../../../../assets/icons/premium/thumbnail/royale-onyx.jpg"),
  imgProduct: require("../../../../assets/icons/premium/products/royale-onyx.jpg"),
  text: "A sophisticated design in white with a black edge",
  amount: "12pc Set",
  stock: "RYX0112",
  accImg: require("../../../../assets/icons/premium/thumbnail/royale-onyx-acc.jpg"),
  accImgProduct: require("../../../../assets/icons/premium/products/royale-onyx-acc.jpg"),
  accName: "Suggested Accessories",
  accProductName: "Black Cutlery",
  accAmount: "",
  accStock: "",
};
export const pebble = {
  name: "Pebble Sky",
  img: require("../../../../assets/icons/premium/thumbnail/pebble.jpg"),
  imgProduct: require("../../../../assets/icons/premium/products/pebble.jpg"),
  text: "A premium quality dinner set with a subtle pattern and colour.",
  amount: "12pc Set",
  stock: "PB0112",
};

export default function PremiumBody() {
  return (
    <>
      <AllPremiumBody />
    </>
  );
}
