import React from "react";
import Footer from "../../common/components/footer";
import NonSlipHeader from "../components/non-slip/header";
import NonSlipBody from "../components/non-slip/body";
import ScrollToTop from "../../common/components/scroll-button";
import RangeCategories, {
  RangeCategoriesForMobile,
} from "../components/range-body-components/range-categories";

export default function NonSlip() {
  return (
    <>
      <ScrollToTop />
      <NonSlipHeader />
      <RangeCategoriesForMobile />
      <>
        <div className="container ">
          <div className="row  d-flex">
            <div className="col-xl-4 col-12 d-none d-lg-block">
              <RangeCategories />
            </div>
            <div className="col-xl-8 col-12">
              <NonSlipBody />
            </div>
          </div>
        </div>
      </>
      <Footer />
    </>
  );
}
