import React from "react";
import AllPremiumPlusBody from "../../all-products/AllPremiumPlusBody";

export const marble = {
  name: "Marble",
  img: require("../../../../assets/icons/pPlus/thumbnail/marble.jpg"),
  imgProduct: require("../../../../assets/icons/pPlus/products/marble.jpg"),
  text: "An exclusive marble look effect a in grey and white embellished design.",
  amount: "12pc Set",
  stock: "GM0112",
  accImg: require("../../../../assets/icons/pPlus/thumbnail/marble-acc.jpg"),
  accImgProduct: require("../../../../assets/icons/pPlus/products/marble-acc.jpg"),
  accName: "Matching Accessories",
  accProductName: "Two Tone Mugs",
  accAmount: "4pk",
  accStock: "FM424",
};
export const bamboo = {
  name: "Bambo Style",
  img: require("../../../../assets/icons/pPlus/thumbnail/bamboo.jpg"),
  imgProduct: require("../../../../assets/icons/pPlus/products/bamboo.jpg"),
  text: "A natural Bamboo inspired look. A contemporary design made of 100% melamine.",
  amount: "12pc Set",
  stock: "BS0112",
  accImg: require("../../../../assets/icons/pPlus/thumbnail/bamboo-acc.jpg"),
  accImgProduct: require("../../../../assets/icons/pPlus/products/bamboo-acc.jpg"),
  accName: "Matching Accessories",
  accProductName: "Soft White Stacking Mugs",
  accAmount: "4pk",
  accStock: "SW423",
};
export const azure = {
  name: "Azure",
  img: require("../../../../assets/icons/pPlus/thumbnail/azure.jpg"),
  imgProduct: require("../../../../assets/icons/pPlus/products/azure.jpg"),
  text: "The Azure dinnerware set comes in a rich dark blue colour and features a smooth rim design. Its durable and lightweight design is perfectly suitable for marine enthusiasts.",
  amount: "12pc Set",
  amount2: "Dinner Plate 48pc,",
  amount3: "Side Plate 48pc,",
  amount4: "Bowl 48pc,",
  stock: "AZ0112",
  stock2: "AZ406",
  stock3: "AZ301",
  stock4: "AZ101",
  accImg: require("../../../../assets/icons/pPlus/thumbnail/azure-acc.jpg"),
  accImgProduct: require("../../../../assets/icons/pPlus/products/azure-acc.jpg"),
  accName: "Matching Accessories",
  accProductName: "Azure Stacking",
  accAmount: "4pk",
  accStock: "AZ423",
};
export const shades = {
  name: "Shades of Blue",
  img: require("../../../../assets/icons/pPlus/thumbnail/shades.jpg"),
  imgProduct: require("../../../../assets/icons/pPlus/products/shades.jpg"),
  text: "A natural Bamboo inspired look. A contemporary design made of 100% melamine.",
  amount: "12pc Set",
  stock: "SB0112",
  accImg: require("../../../../assets/icons/pPlus/thumbnail/shades-acc.jpg"),
  accImgProduct: require("../../../../assets/icons/pPlus/products/shades-acc.jpg"),
  accName: "Matching Accessories",
  accProductName: "Shades of Stacking Mug",
  accAmount: "4pk",
  accStock: "SB423",
};

export const luna = {
  name: "Luna",
  img: require("../../../../assets/icons/pPlus/thumbnail/luna.jpg"),
  imgProduct: require("../../../../assets/icons/pPlus/products/luna.jpg"),
  className: "luna",
  text: "The Luna design has a clean natural aesthetic, perfect for a contemporary environment. It comes in a subtle ridged texture.",
  amount: "12pc Set",
  stock: "LUN0112",
};
export const gGrey = {
  name: "Granite Gray",
  img: require("../../../../assets/icons/pPlus/thumbnail/gGray.jpg"),
  imgProduct: require("../../../../assets/icons/pPlus/products/gGray.jpg"),
  text: "The Granite design is inspired by the look and texture of natural granite stone. It has an enduring appeal and comes in two colour ways; Aqua, Grey. ",
  amount: "12pc Set",
  amount2: "Dinner Plate 48pc,",
  amount3: "Side Plate 48pc,",
  amount4: "Bowl 48pc,",
  stock: "GG0112",
  stock2: "GG406",
  stock3: "GG301",
  stock4: "GG101",
  accImg: require("../../../../assets/icons/pPlus/thumbnail/gGray-acc.jpg"),
  accImgProduct: require("../../../../assets/icons/pPlus/products/gGray-acc.jpg"),
  accName: "Matching Accessories",
  accProductName: "Granite Grey Stacking Mug",
  accAmount: "4pk",
  accStock: "GG423",
};
export const gAqua = {
  name: "Granite Aqua",
  img: require("../../../../assets/icons/pPlus/thumbnail/gAqua.jpg"),
  imgProduct: require("../../../../assets/icons/pPlus/products/gAqua.jpg"),
  text: "The Granite design is inspired by the look and texture of natural granite stone. It has an enduring appeal and comes in two colour ways; Aqua, Grey.",
  amount: "12pc Set",
  amount2: "Dinner Plate 48pc,",
  amount3: "Side Plate 48pc,",
  amount4: "Bowl 48pc,",
  stock: "GA0112",
  stock2: "GA406",
  stock3: "GA301",
  stock4: "GA101",
  accImg: require("../../../../assets/icons/pPlus/thumbnail/gAqua-acc.jpg"),
  accImgProduct: require("../../../../assets/icons/pPlus/products/gAqua-acc.jpg"),
  accName: "Matching Accessories",
  accProductName: "Granite Aqua Stacking Mug",
  accAmount: "4pk,",
  accStock: "GA423",
};
export const sLatte = {
  name: "Seramika Latte",
  img: require("../../../../assets/icons/pPlus/thumbnail/seramike-latte.jpg"),
  imgProduct: require("../../../../assets/icons/pPlus/products/seramike-latte.jpg"),
  text: "The Seramika Collection has an embossed moulded rim design which looks and feels like real ceramic. Available in two colours.",
  amount: "16pc Set",
  amount2: "Dinner Plate 48pc,",
  amount3: "Side Plate 48pc,",
  amount4: "Bowl 48pc,",
  amount5: "Mug 48pc,",
  stock: "SLA0116",
  stock2: "SLA406",
  stock3: "SLA301",
  stock4: "SLA101",
  stock5: "SLA423",
};
export const sVanilla = {
  name: "Seramika Vanilla",
  img: require("../../../../assets/icons/pPlus/thumbnail/sVanilla.jpg"),
  imgProduct: require("../../../../assets/icons/pPlus/products/sVanilla.jpg"),
  text: "The Seramika Collection has an embossed moulded rim design which looks and feels like real ceramic. Available in two colours.",
  amount: "16pc Set,",
  amount2: "Dinner Plate 48pc,",
  amount3: "Side Plate 48pc,",
  amount4: "Bowl 48pc,",
  amount5: "Mug 48pc,",
  stock: "SV0116",
  stock2: "SV406",
  stock3: "SV301",
  stock4: "SV101",
  stock5: "SV423",
};

export default function PremiumPlusBody() {
  return (
    <>
      <AllPremiumPlusBody />
    </>
  );
}
