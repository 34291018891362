import React, { useCallback, useState } from "react";
import "antd/dist/antd.min.css";

import "./style.css";

import { FaTimes, FaBars } from "react-icons/fa";
import { Col, Container, Row } from "react-bootstrap";

export default function Navbar() {
  var navLinks = document.getElementById("navLinks");
  const [visible, setVisible] = useState(false);

  const handleVisible = useCallback(() => {
    if (navLinks) {
      if (visible) {
        navLinks.style.display = "block";
      } else {
        navLinks.style.display = "none";
      }
    }
    setVisible(!visible);
  }, [visible, navLinks]);

  return (
    <nav>
      <div className="nav-links" id="navLinks">
        <div onClick={handleVisible}>
          <FaTimes className="timesIcon" />
        </div>
        <ul className="navbarMenu">
          <li>
            <a href="/">HOME</a>
          </li>
          {/*  <li>
            <a href="/collections">COLLECTIONS</a>
          </li> */}
          <li className="dropdownMenu">
            <a className="drpBtn">PRODUCTS</a>
            <div className="dropdown-content">
              <Container>
                <Row className="d-flex justify-content-center">
                  <Col className="col-12">
                    <div className="dropdownMenu2">
                      <a className="drpBtn">Melamine Range</a>
                      <div className="dropdown-content2">
                        <Container>
                          <a href="/malemine">New Melamine Range</a>
                          <a href="/non-slip">Non-Slip Range</a>
                          <a href="/pPlus">Premium Plus Range</a>
                          <a href="/premium">Premium Range</a>
                          <a href="/value">Value Range</a>
                          <a href="/camper">Camper Smiles Range</a>
                          <a href="/love-caravanning">Love Caravanning Range</a>
                          <a href="/children">Children’s Range</a>
                        </Container>
                      </div>
                    </div>

                    <a href="/polycarbonate">Polycarbonate Range</a>
                    <div className="dropdownMenu2">
                      <a className="drpBtn">Acrylic Range</a>
                      <div className="dropdown-content2">
                        <Container>
                          <a href="/acrylic">New Acrylic Range</a>
                          {/*     <a href="/love-caravanning">Love Caravanning Range</a>
                          <a href="/children">Children’s Range</a> */}
                        </Container>
                      </div>
                    </div>
                    <div className="dropdownMenu2">
                      <a className="drpBtn">Accessories</a>
                      <div className="dropdown-content2">
                        <Container>
                          {/*  <a href="/camper">Camper Smiles Range</a>
                          <a href="/love-caravanning">Love Caravanning Range</a>
                          <a href="/children">Children’s Range</a> */}
                          <a href="/others-accessories">Others Accessories</a>
                        </Container>
                      </div>
                    </div>
                    <a href="/all-products">All Products</a>
                  </Col>
                </Row>
              </Container>
            </div>
          </li>
          <li>
            <a href="/our-story">FLAMEFIELD</a>
          </li>
          <li>
            <a href="/contact">CONTACT</a>
          </li>
        </ul>
      </div>
      <div onClick={handleVisible}>
        <FaBars className="barsIcon" />
      </div>
    </nav>
  );
}
