import React from "react";
import HeaderComponent from "../../../../../common/components/header";
import "../../../../style/header-style.css";

export default function StoryHeader() {
  return (
    <div className="footerLinkHeadContainer storyContainer">
      <HeaderComponent />
      <div className="footerLinkHeadTextContainer">
        <p className="footerLinkHeadText">About Us</p>
      </div>
    </div>
  );
}
