import React from "react";
import Footer from "../common/components/footer";
import ContactHeader from "../contact/components/header";
import ContactBody from "../contact/components/body";
import ScrollToTop from "../common/components/scroll-button";

export default function Contact() {
  return (
    <>
      <ScrollToTop />
      <ContactHeader />
      <ContactBody />
      <Footer />
    </>
  );
}
