import React from "react";

import "./style.css";

export default function SearchBox() {
  return (
    <div className={"inputSearchContainer"}>
      <input
        type="text"
        className={"inputSearch"}
        placeholder="Search for something.."
      />
      <button type="submit" className="serachButton">
        SEARCH
      </button>
    </div>
  );
}
