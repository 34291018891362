import React, { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Col, Container, Row } from "react-bootstrap";
import { BsTelephoneForward } from "react-icons/bs";
import { FiMail } from "react-icons/fi";
import { GrLocation } from "react-icons/gr";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "./style.css";

interface ErrorList {
  [fieldName: string]: string[];
}

interface FieldFeedbackProps {
  errors: ErrorList | null;
  fieldName: string;
}

export function FieldFeedback({ errors, fieldName }: FieldFeedbackProps) {
  if (errors === null) return null;

  if (!errors[fieldName]) return null;

  return (
    <Form.Control.Feedback type="invalid">
      <ul>
        {errors[fieldName].map((error) => (
          <li>{error}</li>
        ))}
      </ul>
    </Form.Control.Feedback>
  );
}

export default function ContactBody() {
  const [name, setName] = useState("");
  const [telephone, setTelephone] = useState("");
  const [mail, setMail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState<ErrorList | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const captchaRef = useRef<ReCAPTCHA>(null);

  const nameChange = (e: any) => {
    setName(e.target.value);
  };

  const telephoneChange = (e: any) => {
    setTelephone(e.target.value);
  };

  const mailChange = (e: any) => {
    setMail(e.target.value);
  };

  const subjectChange = (e: any) => {
    setSubject(e.target.value);
  };

  const messageChange = (e: any) => {
    setMessage(e.target.value);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    setErrors(null);
    setSuccessMessage(null);

    const captchaResult = captchaRef.current?.getValue();
    if (!captchaResult) {
      // FIXME: Internationalization on the error message
      setErrors({
        captcha: ["You didn't complete the CAPTCHA."],
      });
      return;
    }

    var formData = new FormData();
    formData.append("name", name);
    formData.append("phone", telephone);
    formData.append("email_address", mail);
    formData.append("subject", subject);
    formData.append("message", message);
    formData.append("g-recaptcha-response", captchaResult);

    const response = await fetch(
      "https://b2b.flamefield.com/contactform/submit/",
      {
        method: "POST",
        mode: "cors",
        body: formData,
      }
    );

    if (!response.ok) {
      if (response.status === 400) {
        setErrors(await response.json());
      } else {
        setErrors({
          non_field_errors: [
            "An unknown error occurred. Please try again later.",
          ],
        });
      }

      return;
    }

    setSuccessMessage(
      "Successfully sent your message. We will contact you in a few days."
    );
  };

  return (
    <Container>
      <div className="borderTop"></div>
      <Row className="">
        <div className="consultContainer d-flex justify-content-center">
          <p className="consultText">Contact Us...</p>
        </div>
      </Row>
      <Row>
        <Col className="col-lg-5 col-12">
          <div className="infoContainer">
            <div className="contactHeader">UK Headoffice</div>
            <div className="infoItemContainer">
              <div className="contactImgContainer">
                <BsTelephoneForward className="infoItemImg" />
              </div>
              <p className="infoItemText">01707263646</p>
            </div>
            <div className="infoItemContainer">
              <div className="contactImgContainer">
                <FiMail className="infoItemImg" />
              </div>
              <p className="infoItemText">info@flamefield.com</p>
            </div>
            <div className="infoItemContainer">
              <div className="contactImgContainer">
                <GrLocation className="infoItemImg" />
              </div>
              <p className="infoItemText">
                Unit 22, The I O Centre Hearle Way, HATFIELD, AL1O 9EW
              </p>
            </div>
            <div className="contactHeader">Germany</div>
            <div className="infoItemContainer">
              <div className="contactImgContainer">
                <BsTelephoneForward className="infoItemImg" />
              </div>
              <p className="infoItemText">+4921517827883</p>
            </div>
            {/*  <div className="infoItemContainer">
              <div className="contactImgContainer">
                <FiMail className="infoItemImg" />
              </div>
              <p className="infoItemText">info@flamefield.com</p>
            </div> */}
            <div className="infoItemContainer">
              <div className="contactImgContainer">
                <GrLocation className="infoItemImg" />
              </div>
              <p className="infoItemText">
                Magdeburger Str. 17 47800 <br /> Germany
              </p>
            </div>
          </div>
        </Col>
        <Col className="col-lg-1 d-lg-block d-none">
          <div className="verticalBorder"></div>
        </Col>
        <Col className="col-lg-5 col-12">
          <div className="formContainer">
            <Form onSubmit={handleSubmit} noValidate>
              <FieldFeedback errors={errors} fieldName="non_field_errors" />

              <Form.Group className="mb-3 contacFormGroup">
                <div className="formName">
                  <Form.Label className="contactFormText">Full Name</Form.Label>
                  <Form.Control
                    className="contactFormText formControl"
                    type="name"
                    placeholder="Enter name"
                    value={name}
                    onChange={nameChange}
                    isInvalid={!!errors?.name}
                  />
                  <FieldFeedback errors={errors} fieldName="name" />
                </div>
                <div className="formTel">
                  <Form.Label className="contactFormText">
                    Telephone Number
                  </Form.Label>
                  <Form.Control
                    className="contactFormText formControl"
                    type="text"
                    placeholder="Enter telephone number"
                    value={telephone}
                    onChange={telephoneChange}
                    isInvalid={!!errors?.phone}
                  />
                  <FieldFeedback errors={errors} fieldName="phone" />
                </div>
              </Form.Group>
              <Form.Group className="mb-3 ">
                <Form.Label className="contactFormText">
                  Email address
                </Form.Label>
                <Form.Control
                  className="contactFormText formControl"
                  type="email"
                  placeholder="Enter email"
                  value={mail}
                  onChange={mailChange}
                  isInvalid={!!errors?.email_address}
                />
                <FieldFeedback errors={errors} fieldName="email_address" />
              </Form.Group>

              <Form.Group className="mb-3 ">
                <Form.Label className="contactFormText">
                  Message Subject
                </Form.Label>
                <Form.Control
                  className="contactFormText formControl"
                  type="text"
                  placeholder="Message Subject"
                  value={subject}
                  onChange={subjectChange}
                  isInvalid={!!errors?.subject}
                />
                <FieldFeedback errors={errors} fieldName="subject" />
              </Form.Group>
              <Form.Group className="mb-3 ">
                <Form.Label className="contactFormText">Message</Form.Label>
                <Form.Control
                  className="contactFormText formControl"
                  as={"textarea"}
                  rows={3}
                  placeholder="Message"
                  value={message}
                  onChange={messageChange}
                  isInvalid={!!errors?.message}
                />
                <FieldFeedback errors={errors} fieldName="message" />
              </Form.Group>
              <ReCAPTCHA
                sitekey={`${process.env.REACT_APP_SITE_KEY}`}
                ref={captchaRef}
              />
              <FieldFeedback errors={errors} fieldName="captcha" />
              <Button
                variant="outline-secondary"
                type="submit"
                className="contactSendBtn"
              >
                Send
              </Button>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
