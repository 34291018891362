import React from "react";
import Home from "./pages/Home";
import Collections from "./pages/Collections";
import Flamefield from "./pages/Flamefield";
import Contact from "./pages/Contact";
import NewMalemine from "./products/pages/NewMalemine";
import NonSlip from "./products/pages/NonSlip";
import PremiumPlus from "./products/pages/PremiumPlus";
import Premium from "./products/pages/Premium";
import Value from "./products/pages/Value";
import Camper from "./products/pages/Camper";
import LoveCaravan from "./products/pages/LoveCaravan";
import Children from "./products/pages/Children";
import Polycarbonate from "./products/pages/Polycarbonate";
import Acrylic from "./products/pages/Acrylic";
import AllProducts from "./products/pages/AllProducts";
import OtherAccesories from "./products/pages/OtherAccesories";
import OurStory from "./footer-links/company/pages/OurStory";

import ProductCatalog from "./footer-links/company/pages/ProductCatalog";
import Conditions from "./footer-links/helpfull/pages/Conditions";

import Privacy from "./footer-links/helpfull/pages/Privacy";
import SiteMap from "./footer-links/helpfull/pages/SiteMap";

import BecomeWolesaler from "./footer-links/others/pages/BecomeWholesaler";
import { BrowserRouter, Route, Routes } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/collections" element={<Collections />} />
        <Route path="/flamefield" element={<Flamefield />} />
        <Route path="/contact" element={<Contact />} />

        <Route path="/malemine" element={<NewMalemine />} />
        <Route path="/non-slip" element={<NonSlip />} />
        <Route path="/pPlus" element={<PremiumPlus />} />
        <Route path="/premium" element={<Premium />} />
        <Route path="/value" element={<Value />} />
        <Route path="/camper" element={<Camper />} />
        <Route path="/love-caravanning" element={<LoveCaravan />} />
        <Route path="/children" element={<Children />} />
        <Route path="/polycarbonate" element={<Polycarbonate />} />
        <Route path="/acrylic" element={<Acrylic />} />
        <Route path="/others-accessories" element={<OtherAccesories />} />
        <Route path="/all-products" element={<AllProducts />} />

        <Route path="/our-story" element={<OurStory />} />

        <Route path="/product-catalog" element={<ProductCatalog />} />
        <Route path="/conditions" element={<Conditions />} />

        <Route path="/privacy" element={<Privacy />} />
        <Route path="/site-map" element={<SiteMap />} />

        <Route path="/become-a-wholesaler" element={<BecomeWolesaler />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
