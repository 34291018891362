import React from "react";
import "./style.css";
import { Col, Container, Row } from "react-bootstrap";

export default function StoryBody() {
  return (
    <Container>
      <Row className="d-flex justify-content-center">
        <Col className="col-xl-8 col-lg-12 d-flex justify-content-center">
          <div className="aboutBodyContainer">
            <div className="aboutBodyText">
              Flamefield is a specialist trade supplier of outdoor dining
              products which include break-resistant Melamine Tableware, Acrylic
              & Polycarbonate drinkware and accessories. Our exclusive range of
              tableware designs are created for us by talented British designers
              who focus on developing stylish, innovative commercial designs for
              global markets.
              <br />
              <br />
              Our portfolio which includes signature brands
              <img
                alt=""
                src={require("../../../../../assets/icons/melamine-tableware.jpg")}
                className="aboutImg"
              />
              includes a wide choice of dinnerware and drinkware items ideal for
              al fresco dining. We also offer eye catching Children’s Melamine
              Sets especially designed upon outdoor leisure and vintage holiday
              themes. Our experience in developing, sourcing and supplying
              outdoor dining products allows us to offer b2b bespoke design
              packages for discerning clients. We pride ourselves on friendly,
              efficient and honest service fulfilling the needs of our diverse
              client base in various sectors of the market.
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
