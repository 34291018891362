import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "./style.css";

export function MyModal(props: any) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <h3>{props.productName}</h3>
      </Modal.Header>
      <Modal.Body>
        <div className="modalProductContainer">
          <img src={props.img} className={"modalProductImg"} alt="" />
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}

function AccMyModal(props: any) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <h3>{props.productName}</h3>
      </Modal.Header>
      <Modal.Body>
        <div className="modalProductContainer">
          <img src={props.img} className={"modalProductImg"} alt="" />
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}

function Acc2MyModal(props: any) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <h3>{props.productName}</h3>
      </Modal.Header>
      <Modal.Body>
        <div className="modalProductContainer">
          <img src={props.img} className={"modalProductImg"} alt="" />
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}

export default function RangeProduct({
  productImg,
  productImgProduct,
  productName,
  productAciklma,
  amount,
  amount2,
  amount3,
  amount4,
  stockCode,
  stockCode2,
  stockCode3,
  stockCode4,
  accImage,
  accImageProduct,
  accName,
  accProductName,
  accAmount,
  accStock,
  accDescription,
  accImage2,
  accImageProduct2,
  accName2,
  accProductName2,
  accAmount2,
  accStock2,
  accDescription2,
}: any) {
  const [modalShow, setModalShow] = React.useState(false);
  const [accessoriesModolShow, setAccessoriesModalShow] = React.useState(false);
  const [accessories2ModolShow, setAccessories2ModalShow] =
    React.useState(false);

  return (
    <>
      <Container className="productFullContainer">
        <Row className="d-flex justify-content-center productRowContainer">
          <Col className="col-12  productColContainer">
            <div className="d-flex rangeProductMobile">
              <div
                className="productImgContainer"
                onClick={() => setModalShow(true)}
              >
                <img src={productImg} alt="" className={`productImg `} />
              </div>
              <div className="productTextContainer">
                <p className="productTextHeader boldText">{productName}</p>
                <p className="productAciklamaText">{productAciklma}</p>
                <p className="productAmountText ">
                  {amount} <b className="boldText">{stockCode}</b>
                </p>
                {amount2 && (
                  <>
                    <p className="productAmountText accesssoriesAmountText">
                      {amount2} <b className="boldText">{stockCode2}</b>
                    </p>
                    <p className="productAmountText accesssoriesAmountText">
                      {amount3} <b className="boldText">{stockCode3}</b>
                    </p>
                    <p className="productAmountText accesssoriesAmountText">
                      {amount4} <b className="boldText">{stockCode4}</b>
                    </p>
                  </>
                )}
              </div>
            </div>
          </Col>
        </Row>
        {accName && (
          <Row>
            <Col className="accessoriesFullContainer">
              <p className="accessoriesTypeName">{accName}</p>
              <div className="d-flex accRangeProduct">
                <div
                  className="accessoriesProductImgContainer "
                  onClick={() => setAccessoriesModalShow(true)}
                >
                  <img
                    src={accImage}
                    alt=""
                    className={`accessoriesProductImg `}
                  />
                </div>
                <div className="accessoriesProductTextContainer">
                  <p className="accesssoriesProductTextHeader boldText">
                    {accProductName}
                  </p>
                  <p className="accessoriesProductAciklamaText">
                    {accDescription}
                  </p>
                  <p className="accessoriesProductAmountText">
                    {accAmount} <b className="boldText">{accStock}</b>
                  </p>
                </div>
              </div>
              {accName2 && (
                <>
                  <div className="d-flex">
                    <div
                      className="accessoriesProductImgContainer "
                      onClick={() => setAccessories2ModalShow(true)}
                    >
                      <img
                        src={accImage2}
                        alt=""
                        className={`accessoriesProductImg `}
                      />
                    </div>
                    <div className="accessoriesProductTextContainer">
                      <p className="accesssoriesProductTextHeader boldText">
                        {accProductName2}
                      </p>
                      <p className="accessoriesProductAciklamaText">
                        {accDescription2}
                      </p>
                      <p className="accessoriesProductAmountText">
                        {accAmount2} <b className="boldText">{accStock2}</b>
                      </p>
                    </div>
                  </div>
                </>
              )}
            </Col>
          </Row>
        )}
      </Container>

      <MyModal
        img={productImgProduct}
        show={modalShow}
        productName={productName}
        onHide={() => setModalShow(false)}
      />

      <AccMyModal
        img={accImageProduct}
        show={accessoriesModolShow}
        productName={accProductName}
        onHide={() => setAccessoriesModalShow(false)}
      />

      <Acc2MyModal
        img={accImageProduct2}
        show={accessories2ModolShow}
        productName={accProductName2}
        onHide={() => setAccessories2ModalShow(false)}
      />
    </>
  );
}
