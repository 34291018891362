import React from "react";
import Footer from "../../../common/components/footer";
import StoryHeader from "../components/our-story/header";
import StoryBody from "../components/our-story/body";
import ScrollToTop from "../../../common/components/scroll-button";

export default function OurStory() {
  return (
    <>
      <ScrollToTop />
      <StoryHeader />
      <StoryBody />
      <Footer />
    </>
  );
}
