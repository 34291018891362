import React from "react";
import RangeProduct from "../../range-body-components/range-product";

export default function ChildrenBody() {
  const products = [
    {
      img: require("../../../../assets/icons/children/thumbnail/product1.jpg"),
      imgProduct: require("../../../../assets/icons/children/products/product1.jpg"),
      name: "Happy Holidays",
      amount: "12pc",
      stockCode: "HH005",
    },
    {
      img: require("../../../../assets/icons/children/thumbnail/product2.jpg"),
      imgProduct: require("../../../../assets/icons/children/products/product2.jpg"),
      name: "Charly & Friends",
      amount: "12pc",
      stockCode: "CH005",
    },
    {
      img: require("../../../../assets/icons/children/thumbnail/product3.jpg"),
      imgProduct: require("../../../../assets/icons/children/products/product3.jpg"),
      name: "Harry & Friends",
      amount: "12pc",
      stockCode: "HA005",
    },
    {
      img: require("../../../../assets/icons/children/thumbnail/product4.jpg"),
      imgProduct: require("../../../../assets/icons/children/products/product4.jpg"),
      name: "Fredie & Friends",
      amount: "12pc",
      stockCode: "FR005",
    },
  ];

  return (
    <>
      {products.map((product) => (
        <RangeProduct
          productImg={product.img}
          productImgProduct={product.imgProduct}
          productName={product.name}
          amount={product.amount}
          stockCode={product.stockCode}
        />
      ))}
    </>
  );
}
