import React from "react";
import HeaderComponent from "../../../../../common/components/header";
import "../../../../style/header-style.css";

export default function PrivacyHeader() {
  return (
    <div className="footerLinkHeadContainer privacyContainer">
      <HeaderComponent />
      <div className="footerLinkHeadTextContainer">
        <p className="footerLinkHeadText">Privacy & Cookies</p>
      </div>
    </div>
  );
}
