import React from "react";
import "./style.css";
import { Col, Container } from "react-bootstrap";
export default function TextField() {
  return (
    <Container className="headerTextContainer">
      <Col className="headerHeader  ">
        <p className="headerHeaderText">Alfresco dining at its best</p>
      </Col>
      <Col className="col-m-6 col-12">
        <p className="headerBodyText">
          Specialist trade supplier of break resistant. Melamine Tableware,
          Acrylic {"&"} Polycarbonate drinkware {"&"} accessories. Ideal for
          outdoor leisure and recreational use, barbecues, parties and picnics.
        </p>
      </Col>
    </Container>
  );
}
