import React from "react";
import HeaderComponent from "../../../common/components/header";
import "./style.css";

export default function FlamefieldHeader() {
  return (
    <div className="flamefieldContainer">
      <HeaderComponent />
      <div className="flamefieldTextContainer">
        <p className="flamefieldText">Flamefield</p>
      </div>
    </div>
  );
}
