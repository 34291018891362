import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./style.css";

export default function MapBody(props: any) {
  const pagesRanges = [
    {
      id: 1,
      name: "Camper Smiles Range",
      path: "/camper",
    },
    {
      id: 2,
      name: "Children's Range",
      path: "/camper",
    },
    {
      id: 3,
      name: "Love Caravanning Range",
      path: "/love-caravannning",
    },
    {
      id: 4,
      name: "New Acrylic Range",
      path: "/acrylic",
    },
    {
      id: 5,
      name: "New Malemine Range",
      path: "/malemine",
    },
    {
      id: 6,
      name: "Non-Slip Range",
      path: "/non-slip",
    },
    {
      id: 7,
      name: "Others Accessories",
      path: "/others-accessories",
    },
    {
      id: 8,
      name: "Polycarbonate Range",
      path: "/polycarbonate",
    },
    {
      id: 9,
      name: "Premium Range",
      path: "/premium",
    },
    {
      id: 10,
      name: "Premium Plus Range",
      path: "/pPlus",
    },
    {
      id: 11,
      name: "Value Range",
      path: "/value",
    },
  ];
  const pagesCompany = [
    {
      id: 0,
      name: "Contact",
      path: "/contact",
    },
    {
      id: 1,
      name: "Home",
      path: "/",
    },

    {
      id: 2,
      name: "Our Story",
      path: "/our-story",
    },
    {
      id: 3,
      name: "Privacy & Cookies",
      path: "/privacy",
    },
    {
      id: 4,
      name: "Product Catalog",
      path: "/product-catalog",
    },
    {
      id: 5,
      name: "Terms & Conditions",
      path: "/conditions",
    },
  ];
  console.log(props);
  return (
    <>
      <Container className="">
        <Row className="pageHeadText ">- Company</Row>
        <Row>
          <Col className="col-lg-6 col-12 ">
            <div className="pagesContainer">
              <ul>
                {pagesCompany.map((pageComp) => (
                  <li key={pageComp.id} className="pageTextContainer">
                    <a href={pageComp.path} className="pageText">
                      {pageComp.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </Col>
        </Row>

        <Row className="pageHeadText " id="products">
          - Product Ranges
        </Row>
        <Row>
          <Col className="col-lg-6 col-12 ">
            <div className="pagesContainer">
              <ul>
                {pagesRanges.map((pageRange) => (
                  <li key={pageRange.id} className="pageTextContainer">
                    <a href={pageRange.path} className="pageText">
                      {pageRange.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
