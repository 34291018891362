import React from "react";
import RangeProduct from "../range-body-components/range-product";

import { summer, stripes } from "../value/body";

export default function AllValueBody() {
  return (
    <>
      <RangeProduct
        productImg={summer.img}
        productImgProduct={summer.imgProduct}
        productName={summer.name}
        amount={summer.amount}
        stockCode={summer.stock}
        productAciklma={summer.text}
      />

      <RangeProduct
        productImg={stripes.img}
        productImgProduct={stripes.imgProduct}
        productName={stripes.name}
        amount={stripes.amount}
        stockCode={stripes.stock}
        productAciklma={stripes.text}
      />
    </>
  );
}
