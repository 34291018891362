import React from "react";
import HeaderComponent from "../../../../../common/components/header";
import "../../../../style/header-style.css";

export default function CatalogHeader() {
  return (
    <div className="footerLinkHeadContainer catalogContainer">
      <HeaderComponent />
      <div className="footerLinkHeadTextContainer">
        <p className="footerLinkHeadText">Product Catalog</p>
      </div>
    </div>
  );
}
