import React from "react";
import Footer from "../../../common/components/footer";
import ConditionsHeader from "../components/conditions/header";
import ConditionsBody from "../components/conditions/body";
import ScrollToTop from "../../../common/components/scroll-button";

export default function Conditions() {
  return (
    <>
      <ScrollToTop />
      <ConditionsHeader />
      <ConditionsBody />
      <Footer />
    </>
  );
}
