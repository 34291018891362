import React from "react";
import Footer from "../../../common/components/footer";
import CatalogHeader from "../components/product-catalog/header";
import BodyComponent from "../../../common/components/body-component";
import ScrollToTop from "../../../common/components/scroll-button";

export default function ProductCatalog() {
  return (
    <>
      <ScrollToTop />
      <CatalogHeader />
      <BodyComponent />
      <Footer />
    </>
  );
}
