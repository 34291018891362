import React from "react";
import AllCamperBody from "../../all-products/AllCamperBody";

export const smiles = {
  name: "Camper Smiles",
  img: require("../../../../assets/icons/camper/thumbnail/camper-smiles.jpg"),
  imgProduct: require("../../../../assets/icons/camper/products/camper-smiles.jpg"),
  className: "camperImg",
  text: "The Camper Smiles series has been inspired by the ultimate vintage 1950’s campervanning times. This Camper Smiles premium plus set includes 16ltr cooler bag with matching dinner set.",
  amount: "12pc Set,",
  amount2: "Dinner Plate,",
  amount3: "Side Plate,",
  amount4: "Bowl ,",
  stock: "CAM0113",
  stock2: "CAM406",
  stock3: "CAM301",
  stock4: "CAM101",
};
export const smilesMug = {
  name: "Camper Smiles Mugs",
  img: require("../../../../assets/icons/camper/thumbnail/camper-acc.jpg"),
  imgProduct: require("../../../../assets/icons/camper/products/camper-acc.jpg"),
  amount: "4pc,",
  stock: "CAM424",
};
export const backPack = {
  name: "Backpack Set",
  img: require("../../../../assets/icons/camper/thumbnail/camper2-acc.jpg"),
  imgProduct: require("../../../../assets/icons/camper/products/camper2-acc.jpg"),
  className: "camperImg",
  amount: "6pieces Children's set,",
  stock: "CSJ006",
};
export const camperSalad = {
  name: "Salad Bowl",
  img: require("../../../../assets/icons/camper/thumbnail/camper3-acc.jpg"),
  imgProduct: require("../../../../assets/icons/camper/products/camper3-acc.jpg"),
  amount: "24pc Set,",
  text: "Approx. 9.8in dia. salad bowl.",
  stock: "CAM311",
};
export const platter = {
  name: "Rectangular Platter",
  img: require("../../../../assets/icons/camper/thumbnail/camper4-acc.jpg"),
  imgProduct: require("../../../../assets/icons/camper/products/camper4-acc.jpg"),
  amount: "24pc,",
  stock: "CAM354",
};
export const tumblers = {
  name: "Tumblers",
  img: require("../../../../assets/icons/camper/thumbnail/tumblers.jpg"),
  imgProduct: require("../../../../assets/icons/camper/products/tumblers.jpg"),
  text: "Short and tall printed tumblers",
  amount: "2pc,",
  stock: "CAM693, CAM694",
};
export const cutlery = {
  name: "Stripe Cutlery",
  img: require("../../../../assets/icons/camper/thumbnail/cutlery.jpg"),
  imgProduct: require("../../../../assets/icons/camper/products/cutlery.jpg"),
  text: "Premium quality hand polished stainless steel set with colourful round striped handles that are comfortable to hold.",
  amount: "12 Sets,",
  stock: "CS-958",
};
export const picnic = {
  name: "Picnic Rug",
  img: require("../../../../assets/icons/camper/thumbnail/picnic-rug.jpg"),
  imgProduct: require("../../../../assets/icons/camper/products/picnic-rug.jpg"),
  text: "150cm x 135cm Fleece picnic rug with waterproof backing",
  amount: "12Pc,",
  stock: "CAM159",
};
export const place = {
  name: "Place Mats",
  img: require("../../../../assets/icons/camper/thumbnail/place.jpg"),
  imgProduct: require("../../../../assets/icons/camper/products/place.jpg"),
  text: "Decorative set of 4 different colours placemats adds a touch of colour to the table.",
  amount: "24pc,",
  stock: "PM-401",
};

export default function CamperBody() {
  return (
    <>
      <AllCamperBody />
    </>
  );
}
