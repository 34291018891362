import React from "react";
import HeaderComponent from "../../../common/components/header";
import "./style.css";

export default function CollectionsHeader() {
  return (
    <div className="collectionContainer">
      <HeaderComponent />
      <div className="collectionTextContainer">
        <p className="collText">Collections</p>
      </div>
    </div>
  );
}
