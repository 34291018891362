import React from "react";
import "./style.css";
import { Container, Row, Col } from "react-bootstrap";
/* import { FaPinterest } from "react-icons/fa"; */
import { BsTelephoneForward } from "react-icons/bs";
import { TbLocation } from "react-icons/tb";
import { FiMail } from "react-icons/fi";
/* import {
  AiFillTwitterCircle,
  AiFillYoutube,
  AiFillInstagram,
} from "react-icons/ai"; */

export default function Footer() {
  return (
    <>
      <div className="footerContainer">
        <Container className=" py-4 highFooterContainer">
          <Row>
            <Col className="col-xxl-3 col-lg-3">
              <div className="colContainerHeader">UK Headoffice</div>
              <div className="colContainer">
                <TbLocation className="footerIcon" />

                <p className="telText">
                  Unit 22, The I O Centre Hearle Way, HATFIELD, ALIO 9EW
                </p>
              </div>
              <div className="colContainer">
                <BsTelephoneForward className="footerIcon" />

                <p className="telText">01707263646</p>
              </div>
              <div className="colContainer">
                <FiMail className="footerIcon" />

                <p className="telText">info@flamefield.com</p>
              </div>
              <div className="colContainerHeader">Germany</div>
              <div className="colContainer">
                <TbLocation className="footerIcon" />

                <p className="telText">
                  Magdeburger Str. 17 47800 <br /> Germany
                </p>
              </div>
              <div className="colContainer">
                <BsTelephoneForward className="footerIcon" />

                <p className="telText">+4921517827883</p>
              </div>

              {/* Email */}
              {/* <div className="colContainer">
                <FiMail className="footerIcon" />

                <p className="telText">info@flamefield.com</p>
              </div> */}

              {/* Social İcon */}
              {/*   <div className="socialContainer">
                <BsFacebook className="socialIcon face" />
                <AiFillTwitterCircle className="socialIcon tiwit" />
                <FaPinterest className="socialIcon pint" />
                <AiFillInstagram className="socialIcon insta" />
                <AiFillYoutube className="socialIcon youtube" />
              </div> */}

              {/* B2B for Mobile */}
              <div className="colContainerHeader mobilB2B">B2B Network</div>
              <div className="colContainerBody h-100 mobilB2B mobilb2bBody">
                <a href="/become-a-wholesaler">
                  <p className="colBodyText">Become a Wholesaler</p>
                </a>
                <a
                  href="https://b2b.flamefield.com/"
                  rel="nofollow"
                  target={"_blank"}
                >
                  <p className="colBodyText">B2B Login</p>
                </a>
              </div>
            </Col>
            <Col className=" col-lg-2 company">
              <div className="colContainerHeader">Company</div>
              <div className="colContainerBody">
                <a href="/">
                  <p className="colBodyText">Home</p>
                </a>
                <a href="/our-story">
                  <p className="colBodyText">Our Story</p>
                </a>
                <a href="files/NEW_COLLECTION.pdf" target={"_blank"}>
                  <p className="colBodyText">Product Catalog</p>
                </a>
                <a href="/contact">
                  <p className="colBodyText">Contact</p>
                </a>
                <a href="/conditions">
                  <p className="colBodyText">Terms & Conditions</p>
                </a>

                <a href="/privacy">
                  <p className="colBodyText">Privacy & Cookies </p>
                </a>
                <a href="/site-map">
                  <p className="colBodyText">Sitemap</p>
                </a>
                <div className="colContainerHeader">B2B Network</div>
                <div className="colContainerBody h-100">
                  <a href="/become-a-wholesaler">
                    <p className="colBodyText">Become a Wholesaler</p>
                  </a>
                  <a
                    href="https://b2b.flamefield.com/"
                    rel="nofollow"
                    target={"_blank"}
                  >
                    <p className="colBodyText">B2B Login</p>
                  </a>
                </div>
              </div>
            </Col>
            <Col className="col-lg-2 pRange">
              <div className="colContainerHeader ">Melamine</div>

              <div className="colContainerBody">
                <a href="/malemine">
                  <p className="colBodyText">New Melamine Range</p>
                </a>
                <a href="/non-slip">
                  <p className="colBodyText">Non-Slip Range</p>
                </a>
                <a href="/pPlus">
                  <p className="colBodyText">Premium Plus Range</p>
                </a>
                <a href="/premium">
                  <p className="colBodyText">Premium Range</p>
                </a>
                <a href="/value">
                  <p className="colBodyText">Value Range</p>
                </a>
                <a href="/camper">
                  <p className="colBodyText">Camper Smiles Range</p>
                </a>
                <a href="/love-caravanning">
                  <p className="colBodyText">Love Caravanning Range</p>
                </a>
                <a href="/children">
                  <p className="colBodyText">Children's Range</p>
                </a>
              </div>
            </Col>

            <Col className="col-xxl-3 col-lg-3 pRange">
              <div className="colContainerHeader ">Polycarbonate & Acrylic</div>

              <a href="/polycarbonate">
                <p className="colBodyText ">Polycarbonate Range</p>
              </a>

              <a href="/acrylic">
                <p className="colBodyText">New Acrylic Range</p>
              </a>
              {/*   <a href="/love-caravanning">
                <p className="colBodyText">Love Caravanning Range</p>
              </a>
              <a href="/children">
                <p className="colBodyText">Children's Range</p>
              </a> */}
            </Col>
            <Col className="col-xxl-2 col-lg-2 pRange">
              <div className="colContainerHeader ">Accessories</div>
              <div className="colContainerBody">
                {/*  <a href="/camper">
                  <p className="colBodyText">Camper Smiles Range</p>
                </a>
                <a href="/love-caravanning">
                  <p className="colBodyText">Love Caravanning Range</p>
                </a>
                <a href="/children">
                  <p className="colBodyText">Children's Range</p>
                </a> */}
                <a href="/others-accessories">
                  <p className="colBodyText">Others Accessories</p>
                </a>
              </div>
            </Col>
          </Row>
        </Container>
        <Container className="footerC highFooterContainer borderContainer">
          {/*  <div className="">
            <img
              src={require("../../../assets/icons/apps.png")}
              alt=""
              className="appImg"
            />
          </div> */}
          <Row className="">
            <Col className="col-6">© 2022 Flamefield, All Rights Reserved</Col>
            <Col className="col-6 d-flex justify-content-end">
              <a
                href="https://www.boranka.com/tr"
                target={"_blank"}
                rel="noreferrer"
              >
                <img
                  alt=""
                  src={require("../../../assets/icons/boranka-black.png")}
                  className="boranka"
                />
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
