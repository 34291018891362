import React from "react";
import Header from "../home/components/header";
import Body from "../home/components/body";
import Range from "../home/components/range";
import Footer from "../common/components/footer";
import ScrollToTop from "../common/components/scroll-button";

export default function Home() {
  return (
    <>
      <ScrollToTop />
      <Header />
      <Body />
      <Range />
      <Footer />
    </>
  );
}
