import React from "react";
import RangeProduct from "../range-body-components/range-product";

export default function AllNonSlipBody() {
  const nonMelamine = {
    name: "Non-Slip Malemine",
    img: require("../../../assets/icons/non-slip/thumbnail/non-melamine.jpg"),
    imgProduct: require("../../../assets/icons/non-slip/products/non-melamine.jpg"),
    text: "A high quality anti slip dinnerware set in cool white and cool grey colour. Comes with a rim design, feeling and looking like real ceramic",
    amount: "12pc Set Cool Gray",
    stock: "NSG0112",
  };
  const nonMelamine2 = {
    name: "Non-Slip Malemine",
    img: require("../../../assets/icons/non-slip/thumbnail/non-melamine-2.jpg"),
    imgProduct: require("../../../assets/icons/non-slip/products/non-melamine-2.jpg"),
    text: "A high quality anti slip dinnerware set in cool white and cool grey colour. Comes with a rim design, feeling and looking like real ceramic",
    amount: "12pc Set Cool White",
    stock: "NSW0112",
    accImg: require("../../../assets/icons/non-slip/thumbnail/non-melamine-acc.jpg"),
    accImgProduct: require("../../../assets/icons/non-slip/products/non-melamine-acc.jpg"),
    accName: "Matching Accessories",
    accProductName: "Non-slip Mug",
    accAmount: "Cool White Mug",
    accStock: "NSW424",
  };
  const dunes = {
    name: "Dunes",
    img: require("../../../assets/icons/non-slip/thumbnail/dunes.jpg"),
    imgProduct: require("../../../assets/icons/non-slip/products/dunes.jpg"),
    text: "A premium quality dinner set in a dreamy abstract design and glossy finish. The set has an anti slip base which ensures a secure hold during transit.",
    amount: "12pc Set,",
    stock: "DUN0112",
    accImg: require("../../../assets/icons/non-slip/thumbnail/dunes-acc.jpg"),
    accImgProduct: require("../../../assets/icons/non-slip/products/dunes-acc.jpg"),
    accName: "Suggested Accessories",
    accProductName: "Moonstone Tumblers",
    accAmount: "",
    accStock: "",
  };

  return (
    <>
      <RangeProduct
        productImg={nonMelamine.img}
        productImgProduct={nonMelamine.imgProduct}
        productName={nonMelamine.name}
        amount={nonMelamine.amount}
        stockCode={nonMelamine.stock}
        productAciklma={nonMelamine.text}
      />

      <RangeProduct
        productImg={nonMelamine2.img}
        productImgProduct={nonMelamine2.imgProduct}
        productName={nonMelamine2.name}
        amount={nonMelamine2.amount}
        stockCode={nonMelamine2.stock}
        productAciklma={nonMelamine2.text}
        accImage={nonMelamine2.accImg}
        accImageProduct={nonMelamine2.accImgProduct}
        accName={nonMelamine2.accName}
        accProductName={nonMelamine2.accProductName}
        accAmount={nonMelamine2.accAmount}
        accStock={nonMelamine2.accStock}
      />

      <RangeProduct
        productImg={dunes.img}
        productImgProduct={dunes.imgProduct}
        productName={dunes.name}
        amount={dunes.amount}
        stockCode={dunes.stock}
        productAciklma={dunes.text}
        accImage={dunes.accImg}
        accImageProduct={dunes.accImgProduct}
        accName={dunes.accName}
        accProductName={dunes.accProductName}
        accAmount={dunes.accAmount}
        accStock={dunes.accStock}
      />
    </>
  );
}
