import React from "react";
import HeaderComponent from "../../../../common/components/header";
import "../../../style/header-style.css";

export default function NonSlipHeader() {
  return (
    <div className="productRangeHeadContainer slipContainer">
      <HeaderComponent />
      <div className="productRangeHeadTextContainer">
        <p className="productRangeHeadText">Non-Slip Range</p>
      </div>
    </div>
  );
}
