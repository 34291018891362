import React from "react";

import ChildrenBody from "../../children/body";
import PolyBody from "../../polycarbonate/body";
import AcrylicBody from "../../acrylic/body";
import OthersBody from "../../others-range/body";
import AllNewMalemineBody from "../NewMalemineBody";
import AllNonSlipBody from "../AllNonSlipBody";
import AllPremiumPlusBody from "../AllPremiumPlusBody";
import AllPremiumBody from "../AllPremiumBody";
import AllValueBody from "../AllValueBody";
import AllCamperBody from "../AllCamperBody";
import AllCaravanBody from "../AllCaravanBody";

import "./style.css";

export default function AllProductBody() {
  return (
    <>
      <div className="firstMargin"></div>
      <div className="d-flex justify-content-center">
        <p className="allProductHeader">New Melamine Range</p>
      </div>
      <AllNewMalemineBody />
      <div className="d-flex justify-content-center">
        <p className="allProductHeader">Non-Slip Range</p>
      </div>
      <AllNonSlipBody />

      <div className="d-flex justify-content-center">
        <p className="allProductHeader">Premium Plus Range</p>
      </div>
      <AllPremiumPlusBody />

      <div className="d-flex justify-content-center">
        <p className="allProductHeader">Premium Range</p>
      </div>
      <AllPremiumBody />

      <div className="d-flex justify-content-center">
        <p className="allProductHeader">Value Range</p>
      </div>
      <AllValueBody />

      <div className="d-flex justify-content-center">
        <p className="allProductHeader">Camper Smiles Range</p>
      </div>
      <AllCamperBody />

      <div className="d-flex justify-content-center">
        <p className="allProductHeader">Love Caravanning Range</p>
      </div>
      <AllCaravanBody />

      <div className="d-flex justify-content-center">
        <p className="allProductHeader">Children's Range</p>
      </div>
      <ChildrenBody />

      <div className="d-flex justify-content-center">
        <p className="allProductHeader">Polycarbonate Range</p>
      </div>
      <PolyBody />

      <div className="d-flex justify-content-center">
        <p className="allProductHeader">New Acrylic Range</p>
      </div>
      <AcrylicBody />

      <div className="d-flex justify-content-center">
        <p className="allProductHeader">Others Accessories</p>
      </div>
      <OthersBody />
    </>
  );
}
