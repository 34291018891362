import React from "react";
import RangeProduct from "../range-body-components/range-product";

import {
  marble,
  bamboo,
  shades,
  azure,
  luna,
  gGrey,
  gAqua,
  sLatte,
  sVanilla,
} from "../premium-plus/body";

export default function AllPremiumPlusBody() {
  return (
    <>
      <RangeProduct
        productImg={marble.img}
        productImgProduct={marble.imgProduct}
        productName={marble.name}
        amount={marble.amount}
        stockCode={marble.stock}
        productAciklma={marble.text}
        accImage={marble.accImg}
        accImageProduct={marble.accImgProduct}
        accName={marble.accName}
        accProductName={marble.accProductName}
        accAmount={marble.accAmount}
        accStock={marble.accStock}
      />

      <RangeProduct
        productImg={bamboo.img}
        productImgProduct={bamboo.imgProduct}
        productName={bamboo.name}
        amount={bamboo.amount}
        stockCode={bamboo.stock}
        productAciklma={bamboo.text}
        accImage={bamboo.accImg}
        accImageProduct={bamboo.accImgProduct}
        accName={bamboo.accName}
        accProductName={bamboo.accProductName}
        accAmount={bamboo.accAmount}
        accStock={bamboo.accStock}
      />

      <RangeProduct
        productImg={azure.img}
        productImgProduct={azure.imgProduct}
        productName={azure.name}
        amount={azure.amount}
        amount2={azure.amount2}
        amount3={azure.amount3}
        amount4={azure.amount4}
        stockCode={azure.stock}
        stockCode2={azure.stock2}
        stockCode3={azure.stock3}
        stockCode4={azure.stock4}
        productAciklma={azure.text}
        accImage={azure.accImg}
        accImageProduct={azure.accImgProduct}
        accName={azure.accName}
        accProductName={azure.accProductName}
        accAmount={azure.accAmount}
        accStock={azure.accStock}
      />

      <RangeProduct
        productImg={shades.img}
        productImgProduct={shades.imgProduct}
        productName={shades.name}
        amount={shades.amount}
        stockCode={shades.stock}
        productAciklma={shades.text}
        accImage={shades.accImg}
        accImageProduct={shades.accImgProduct}
        accName={shades.accName}
        accProductName={shades.accProductName}
        accAmount={shades.accAmount}
        accStock={shades.accStock}
      />

      <RangeProduct
        productImg={luna.img}
        productImgProduct={luna.imgProduct}
        productName={luna.name}
        amount={luna.amount}
        stockCode={luna.stock}
        productAciklma={luna.text}
      />

      <RangeProduct
        productImg={gGrey.img}
        productImgProduct={gGrey.imgProduct}
        productName={gGrey.name}
        amount={gGrey.amount}
        amount2={gGrey.amount2}
        amount3={gGrey.amount3}
        amount4={gGrey.amount4}
        stockCode={gGrey.stock}
        stockCode2={gGrey.stock2}
        stockCode3={gGrey.stock3}
        stockCode4={gGrey.stock4}
        productAciklma={gGrey.text}
        accImage={gGrey.accImg}
        accImageProduct={gGrey.accImgProduct}
        accName={gGrey.accName}
        accProductName={gGrey.accProductName}
        accAmount={gGrey.accAmount}
        accStock={gGrey.accStock}
      />

      <RangeProduct
        productImg={gAqua.img}
        productImgProduct={gAqua.imgProduct}
        productName={gAqua.name}
        amount={gAqua.amount}
        amount2={gAqua.amount2}
        amount3={gAqua.amount3}
        amount4={gAqua.amount4}
        stockCode={gAqua.stock}
        stockCode2={gAqua.stock2}
        stockCode3={gAqua.stock3}
        stockCode4={gAqua.stock4}
        productAciklma={gAqua.text}
        accImage={gAqua.accImg}
        accImageProduct={gAqua.accImgProduct}
        accName={gAqua.accName}
        accProductName={gAqua.accProductName}
        accAmount={gAqua.accAmount}
        accStock={gAqua.accStock}
      />

      <RangeProduct
        productImg={sLatte.img}
        productImgProduct={sLatte.imgProduct}
        productName={sLatte.name}
        amount={sLatte.amount}
        amount2={sLatte.amount2}
        amount3={sLatte.amount3}
        amount4={sLatte.amount4}
        stockCode={sLatte.stock}
        stockCode2={sLatte.stock2}
        stockCode3={sLatte.stock3}
        stockCode4={sLatte.stock4}
        productAciklma={sLatte.text}
      />

      <RangeProduct
        productImg={sVanilla.img}
        productImgProduct={sVanilla.imgProduct}
        productName={sVanilla.name}
        amount={sVanilla.amount}
        amount2={sLatte.amount2}
        amount3={sLatte.amount3}
        amount4={sLatte.amount4}
        stockCode={sLatte.stock}
        stockCode2={sLatte.stock2}
        stockCode3={sLatte.stock3}
        stockCode4={sLatte.stock4}
        productAciklma={sVanilla.text}
      />
    </>
  );
}
