import React from "react";
import RangeProduct from "../../range-body-components/range-product";

export default function PolyBody() {
  const products = [
    {
      img: require("../../../../assets/icons/polycarbonate/thumbnail/product1.jpg"),
      imgProduct: require("../../../../assets/icons/polycarbonate/products/product1.jpg"),
      name: "Standart Wine",
      aciklama: "Extra fine Polycarbonate looks and feels like real glass.",
      amount: "2pk",
      stockCode: "SP1665",
    },
    {
      img: require("../../../../assets/icons/polycarbonate/thumbnail/product2.jpg"),
      imgProduct: require("../../../../assets/icons/polycarbonate/products/product2.jpg"),
      name: "Large Wine",
      aciklama: "Extra fine Polycarbonate looks and feels like real glass.",
      amount: "2pk",
      stockCode: "SP0952",
    },
    {
      img: require("../../../../assets/icons/polycarbonate/thumbnail/product3.jpg"),
      imgProduct: require("../../../../assets/icons/polycarbonate/products/product3.jpg"),
      name: "Short Tumbler",
      aciklama: "Extra fine Polycarbonate looks and feels like real glass.",
      amount: "2pk",
      stockCode: "SP1673",
    },
    {
      img: require("../../../../assets/icons/polycarbonate/thumbnail/product4.jpg"),
      imgProduct: require("../../../../assets/icons/polycarbonate/products/product4.jpg"),
      name: "Tall Tumbler",
      aciklama: "Extra fine Polycarbonate looks and feels like real glass.",
      amount: "2pk",
      stockCode: "SP1780",
    },
    {
      img: require("../../../../assets/icons/polycarbonate/thumbnail/product5.jpg"),
      imgProduct: require("../../../../assets/icons/polycarbonate/products/product5.jpg"),
      name: "Savoy Wine Glass",
      aciklama: "Extra fine Polycarbonate looks and feels like real glass.",
      amount: "2pk",
      stockCode: "SP1748",
    },
    {
      img: require("../../../../assets/icons/polycarbonate/thumbnail/product6.jpg"),
      imgProduct: require("../../../../assets/icons/polycarbonate/products/product6.jpg"),
      name: "Savoy Large Wine Glass",
      aciklama: "Extra fine Polycarbonate looks and feels like real glass.",
      amount: "2pk",
      stockCode: "SP1747",
    },
    {
      img: require("../../../../assets/icons/polycarbonate/thumbnail/product7.jpg"),
      imgProduct: require("../../../../assets/icons/polycarbonate/products/product7.jpg"),
      name: "Savoy Short Tumbler",
      aciklama: "Extra fine Polycarbonate looks and feels like real glass",
      amount: "2pk",
      stockCode: "SP1743",
    },
    {
      img: require("../../../../assets/icons/polycarbonate/thumbnail/product8.jpg"),
      imgProduct: require("../../../../assets/icons/polycarbonate/products/product8.jpg"),
      name: "Savoy Champagne Flute",
      aciklama: "Extra fine Polycarbonate looks and feels like real glass.",
      amount: "2pk",
      stockCode: "SP1767",
    },
    {
      img: require("../../../../assets/icons/polycarbonate/thumbnail/product9.jpg"),
      imgProduct: require("../../../../assets/icons/polycarbonate/products/product9.jpg"),
      name: "Double Walled Mug",
      aciklama: "Polycarbonate looks and feels like real glass..",
      amount: "24pc",
      stockCode: "DW432",
    },
    {
      img: require("../../../../assets/icons/polycarbonate/thumbnail/product10.jpg"),
      imgProduct: require("../../../../assets/icons/polycarbonate/products/product10.jpg"),
      name: "French Press",
      aciklama:
        "Made from unbreakable Tritan material, it is ideal for both indoor & outdoor leisure use.",
      amount: "12pc",
      stockCode: "FP243",
    },
  ];

  return (
    <>
      {products.map((product) => (
        <RangeProduct
          productImg={product.img}
          productImgProduct={product.imgProduct}
          productName={product.name}
          amount={product.amount}
          stockCode={product.stockCode}
          productAciklma={product.aciklama}
        />
      ))}
    </>
  );
}
