import React from "react";
import RangeProduct from "../range-body-components/range-product";

import {
  caravan,
  caravanMug,
  salad,
  lineSalad,
  shortTumblers,
  tallTumblers,
} from "../love-caravan/body";

export default function AllCaravanBody() {
  return (
    <>
      <RangeProduct
        productImg={caravan.img}
        productImgProduct={caravan.imgProduct}
        productName={caravan.name}
        amount={caravan.amount}
        stockCode={caravan.stock}
        productAciklma={caravan.text}
      />

      <RangeProduct
        productImg={caravanMug.img}
        productImgProduct={caravanMug.imgProduct}
        productName={caravanMug.name}
        amount={caravanMug.amount}
        stockCode={caravanMug.stock}
      />

      <RangeProduct
        productImg={salad.img}
        productImgProduct={salad.imgProduct}
        productName={salad.name}
        amount={salad.amount}
        stockCode={salad.stock}
        productAciklma={salad.text}
      />

      <RangeProduct
        productImg={lineSalad.img}
        productImgProduct={lineSalad.imgProduct}
        productName={lineSalad.name}
        amount={lineSalad.amount}
        stockCode={lineSalad.stock}
        productAciklma={lineSalad.text}
      />

      <RangeProduct
        productImg={shortTumblers.img}
        productImgProduct={shortTumblers.imgProduct}
        productName={shortTumblers.name}
        amount={shortTumblers.amount}
        stockCode={shortTumblers.stock}
        productAciklma={shortTumblers.text}
      />

      <RangeProduct
        productImg={tallTumblers.img}
        productImgProduct={tallTumblers.imgProduct}
        productName={tallTumblers.name}
        amount={tallTumblers.amount}
        stockCode={tallTumblers.stock}
        productAciklma={tallTumblers.text}
      />
    </>
  );
}
