import React from "react";
import Footer from "../../../common/components/footer";
import BecomeHeader from "../components/become-wholesaler/header";
import BecomeBody from "../components/become-wholesaler/body";
import ScrollToTop from "../../../common/components/scroll-button";

export default function BecomeWolesaler() {
  return (
    <>
      <ScrollToTop />
      <BecomeHeader />
      <BecomeBody />
      <Footer />
    </>
  );
}
