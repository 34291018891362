import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./style.css";

export default function PrivacyBody() {
  return (
    <Container>
      <Row className="d-flex justify-content-center">
        <Col className="col-xl-8 col-12 d-flex justify-content-center">
          <div className="privacyBodyContainer">
            <div className="privacyBodyText">
              <p className="privacyHeaderText">Introduction</p>
              This website is owned and operated by Registered Flamefield Ltd.
              For the purposes of the Data Protection Act 1998 and the General
              Data Protection Regulations (GDPR) the data controller is
              Registered Flamefield Ltd.
              <br />
              In this document, "we" or "us" refers to Registered Flamefield
              Ltd.
              <br />
              This privacy policy explains how we use any personal information
              we collect about you when you use our services.
              <br />
              This is a notice to tell you our policy about all information that
              we collect about you and how we use it.
              <br />
              Our policy is both specific and strict. It complies with UK law
              and as a data controller, is bound by the requirements of the
              General Data Protection Regulations (GDPR).
              <p className="privacyHeaderText">
                Personal identification & contact information
              </p>
              You agree that we are entitled to obtain, use and process the
              information you provide to us to enable us to carry out the
              services you have requested.
              <br />
              Personal data relates to any information about you which may
              include:
              <div className="privacyMadde">
                <p>◦ Name</p>
                <p>◦ Date of Birth</p>
                <p>◦ Occupation</p>
                <p>
                  ◦ Contact Information (address, email and telephone numbers)
                </p>
                <p>◦ IP address (automatically collected)</p>
                <p>◦ Cookie Information</p>
                <p>◦ Referring Website Information</p>
              </div>
              <p className="privacyHeaderText">How this information is used</p>
              We understand that users of our website want assurance that their
              data will not be used for any purpose unintended by them and will
              not be passed onto or sold to a third party.
              <br />
              We will only use your personal data when the law allows us to do
              so, and most commonly, to enable us to provide the requested
              services to you:
              <div className="privacyMadde">
                <p>◦ To provide you with the services which you request</p>
                <p>
                  ◦ For verifying your identity for legal and regulatory
                  compliance
                </p>
                <p>◦ Service updates regarding our products and services</p>
              </div>
              We may receive data from you for the purposes of identifying you
              and where you live such as a copy of your passport and proof of
              address, such as a utility bill to meet our ‘Know Your Client’ and
              money laundering requirements.
              <p className="privacyHeaderText">How this information is used</p>
              To prevent unauthorised access, maintain data accuracy and ensure
              the correct use of information, we have put in place appropriate
              physical, electronic and managerial procedures to safeguard and
              secure the information we collect online.
              <br />
              See our Site Security policy for more information.
              <p className="privacyHeaderText">
                How you can access or correct your information
              </p>
              You can access all your personally identifiable information that
              we collect online and maintain by emailing us at
              info@flamefield.com. We use this procedure to better safeguard
              your information.
              <br />
              You can correct factual errors in your personally identifiable
              information by sending us a request that credibly shows error.
              <br />
              To protect your privacy and security, we will also take reasonable
              steps to verify your identity before granting access or making
              corrections.
              <br />
              You may request deletion of your personal information by us. We
              may be required to keep such information and not delete it (or to
              keep this information for a certain time, in which case we will
              comply with your deletion request only after we have fulfilled
              such requirements). When we delete any information, it will be
              deleted from the active database, but may remain in our archives.
              We may also retain your information for fraud prevention or
              similar purposes
              <p className="privacyHeaderText">Cookies</p>
              Cookies are small text files that are placed on your computer's
              hard drive through your web browser when you visit any website.
              They are widely used to make websites work, or work more
              efficiently, as well as to provide information to the owners of
              the site.
              <br />
              Like all other users of cookies, we may request the return of
              information from your computer when your browser requests a web
              page from our server. Cookies enable our web server to identify
              you to us, and to track your actions and the pages you visit while
              you use our website. The cookies we use may last for a single
              visit to our site (they are deleted from your computer when you
              close your browser) or may remain on your computer until you
              delete them or until a defined period of time has passed.
              <br />
              Although your browser software enables you to disable cookies, we
              recommend that you allow the use of cookies to take advantage of
              the features of our website that rely on their use. If you prevent
              their use, you will not be able to use the full functionality of
              our website. The ways we may use cookies are as follows:
              <div className="privacyMadde">
                <p>
                  ◦ To record whether you have accepted the use of cookies on
                  our website. This is solely to comply with the law. If you
                  have chosen not to accept cookies, we will not use cookies for
                  your visit, but unfortunately, our site will not work well for
                  you.
                </p>
                <p>
                  ◦ To allow essential parts of our website to operate for you.
                </p>
                <p>◦ To operate our content management system.</p>
                <p>
                  ◦ To operate the online notification form – the form that you
                  use to contact us for any reason. This cookie is set on your
                  arrival at our website and deleted when you close your
                  browser.
                </p>
                <p>
                  ◦ To enhance security on our contact form, it is set for use
                  only through the contact form. This cookie is deleted when you
                  close your browser.
                </p>
                <p>
                  ◦ To collect information about how visitors use our site, we
                  use the information to improve your experience of our site and
                  enable us to increase sales. This cookie collects information
                  in an anonymous form, including the number of visitors to the
                  site, where visitors have come to the site from and the pages
                  they visited.
                </p>
                <p>
                  ◦ To record that a user has viewed a webcast. It collects
                  information in an anonymous form. This cookie expires when you
                  close your browser.
                </p>
                <p>
                  ◦ To record your activity during a webcast. For example, as to
                  whether you have asked a question or provided an opinion by
                  ticking a box. This information is retained so that we can
                  serve your information to you when you return to the site.
                  This cookie will record an anonymous ID for each user, but it
                  will not use the information for any other purpose. This
                  cookie will last for a period after which it will delete
                  automatically.
                </p>
                <p>
                  ◦ To store your personal information so that you do not have
                  to provide it afresh when you visit the site next time. This
                  cookie will last for a period after which it will delete
                  automatically.
                </p>
                <p>
                  ◦ To enable you to watch videos we have placed on YouTube.
                  YouTube will not store personally identifiable cookie
                  information when you use YouTube's privacy-enhanced mode.
                </p>
              </div>
              <p className="privacyHeaderText">Calling our office</p>
              When you call our office, we may collect Calling Line
              Identification (CLI) information. We use this information to help
              improve the efficiency and effectiveness of our help line.
              <p className="privacyHeaderText">Third party advertising</p>
              Third parties may advertise on our website. In doing so, those
              parties, their agents or other companies working for them may use
              technology that automatically collects your IP address when they
              send an advertisement that appears on our site to your browser.
              They may also use other technology, such as cookies or JavaScript
              to personalise the content of, and to measure the performance of
              their adverts. We do not have control over these technologies or
              the data that these parties obtain. Accordingly, this privacy
              notice does not cover the information practices of these third
              parties.
              <p className="privacyHeaderText">Complaints</p>
              When we receive a complaint, we record all the information you
              have given to us. We use that information to resolve your
              complaint. If your complaint reasonably requires us to contact
              some other person, we may decide to give to that other person some
              of the information contained in your complaint. We do this as
              infrequently as possible, but it is a matter for our sole
              discretion as to whether we do give information, and, if we do,
              what that information is.
              <br />
              We may also compile statistics showing information obtained from
              this source to assess the level of service we provide, but not in
              a way that could identify you or any other person.
              <p className="privacyHeaderText">
                Information we obtain from third parties.
              </p>
              Although we do not disclose your personal information to any third
              party (except as set out in this notice), we do receive data which
              is indirectly made up from your personal information, from
              software services such as Google Analytics and others. No such
              information is identifiable to you.
              <p className="privacyHeaderText">
                Disclosure to Government and their agencies
              </p>
              We are subject to the law like everyone else. We may be required
              to give information to legal authorities if they so request or if
              they have the proper authorisation.
              <p className="privacyHeaderText">
                Data may be "processed" outside the UK
              </p>
              Our websites are hosted in the EU. We also use outsourced services
              in countries outside the EU from time-to-time in other aspects of
              our business. Accordingly, data obtained within the UK may be
              "processed" outside the UK and data obtained in any other country
              may be processed within or outside that country.
              <p className="privacyHeaderText">Compliance with the law</p>
              This privacy policy has been compiled to comply with the law of
              every jurisdiction in which we aim to do business. If you think it
              fails to satisfy the law of your country, we would like to hear
              from you, but ultimately it is your choice as to whether you wish
              to use our website.
              <br />
              If you think our policy falls short of your expectations or that
              we are failing to abide by our policy, do please tell us.
              <br />
              We regret that if there are one or more points below with which
              you are not happy, your only recourse is to leave our website
              immediately. Except as set out above, we do not share, or sell, or
              disclose to a third party, any personally identifiable information
              collected on our websites.
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
