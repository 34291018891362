import React from "react";
import Footer from "../../../common/components/footer";
import PrivacyHeader from "../components/privacy/header";
import PrivacyBody from "../components/privacy/body";
import ScrollToTop from "../../../common/components/scroll-button";

export default function Privacy() {
  return (
    <>
      <ScrollToTop />
      <PrivacyHeader />
      <PrivacyBody />
      <Footer />
    </>
  );
}
