import React from "react";
import HeaderComponent from "../../../../../common/components/header";
import "../../../../style/header-style.css";

export default function ConditionsHeader() {
  return (
    <div className="footerLinkHeadContainer conditionsContainer">
      <HeaderComponent />
      <div className="footerLinkHeadTextContainer">
        <p className="footerLinkHeadText">Terms & Conditions</p>
      </div>
    </div>
  );
}
