import React from "react";
import RangeProduct from "../range-body-components/range-product";

export default function AllNewMalemineBody() {
  const bohoDream = {
    name: "Boho Dream",
    img: require("../../../assets/icons/new-malemine/thumbnail/BOHO_DREAM.jpg"),
    imgProduct: require("../../../assets/icons/new-malemine/products/BOHO_DREAM.jpg"),
    text: "An artistic design that is both creative and chic with an anti-slip base. Set for 4 people.",
    amount: "12pc Set",
    stock: "BD0112",
  };

  const rustic = {
    name: "Rustic Pearl",
    img: require("../../../assets/icons/new-malemine/thumbnail/RUSTIC_PEARL.jpg"),
    imgProduct: require("../../../assets/icons/new-malemine/products/RUSTIC_PEARL.jpg"),
    text: "A simple design with a delicate pearlescent finish, giving a rustic charm. During transit, the anti-slip base gives a secure hold. Set for 4 people.",
    amount: "12pc Set",
    stock: "RP0112",
  };

  const moondust = {
    name: "Moondust",
    img: require("../../../assets/icons/new-malemine/thumbnail/MOONDUST.jpg"),
    imgProduct: require("../../../assets/icons/new-malemine/products/MOONDUST.jpg"),
    text: "A high quality dinner set with an anti-slip base and the deep grey makes this an elegant design but with an air of mystery. Set for 4 people.",
    amount: "12pc Set",
    stock: "MD0112",
  };

  return (
    <>
      <RangeProduct
        productImg={bohoDream.img}
        productImgProduct={bohoDream.imgProduct}
        productName={bohoDream.name}
        amount={bohoDream.amount}
        stockCode={bohoDream.stock}
        productAciklma={bohoDream.text}
      />

      <RangeProduct
        productImg={rustic.img}
        productImgProduct={rustic.imgProduct}
        productName={rustic.name}
        amount={rustic.amount}
        stockCode={rustic.stock}
        productAciklma={rustic.text}
      />

      <RangeProduct
        productImg={moondust.img}
        productImgProduct={moondust.imgProduct}
        productName={moondust.name}
        amount={moondust.amount}
        stockCode={moondust.stock}
        productAciklma={moondust.text}
      />
    </>
  );
}
