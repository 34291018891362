import React from "react";
import AllValueBody from "../../all-products/AllValueBody";

export const summer = {
  name: "Hello Summer",
  img: require("../../../../assets/icons/value/thumbnail/summer.jpg"),
  imgProduct: require("../../../../assets/icons/value/products/summer.jpg"),
  text: "The bright tableware set for 4 people brings summer to the dining table. The mix of four fresh colours makes the crockery set an absolute eye-catcher.",
  amount: "16pc Set,",
  stock: "HS0116",
};

export const stripes = {
  name: "Just Stripes",
  img: require("../../../../assets/icons/value/thumbnail/stripes.jpg"),
  imgProduct: require("../../../../assets/icons/value/products/stripes.jpg"),
  text: "A simple yet cherful value set design. Just stripes comes in a simple look and feel, with a touch of vibrant colours.",
  amount: "16pc Set,",
  stock: "STR0116",
};

export default function ValueBody() {
  return (
    <>
      <AllValueBody />
    </>
  );
}
