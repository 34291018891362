import React from "react";
import HeaderComponent from "../../../../common/components/header";
import "../../../style/header-style.css";

export default function NewMalemineHeader() {
  return (
    <div className="productRangeHeadContainer malemineContainer">
      <HeaderComponent />
      <div className="productRangeHeadTextContainer">
        <p className="productRangeHeadText">New Melamine Range</p>
      </div>
    </div>
  );
}
