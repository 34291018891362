import React from "react";
import HeaderComponent from "../../../../../common/components/header";
import "../../../../style/header-style.css";

export default function BecomeHeader() {
  return (
    <div className="footerLinkHeadContainer becomeContainer">
      <HeaderComponent />
      <div className="footerLinkHeadTextContainer">
        <p className="footerLinkHeadText">Become a Wholesaler</p>
      </div>
    </div>
  );
}
