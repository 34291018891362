import React from "react";
import Footer from "../../../common/components/footer";
import MapHeader from "../components/site-map/header";
import MapBody from "../components/site-map/body";
import ScrollToTop from "../../../common/components/scroll-button";

export default function SiteMap() {
  return (
    <>
      <ScrollToTop />
      <MapHeader />
      <MapBody />
      <Footer />
    </>
  );
}
