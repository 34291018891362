import React from "react";
import "./style.css";
import { Container, Col, Row } from "react-bootstrap";

export default function Body() {
  return (
    <div className="bodyHeadContainer">
      <Container className="p-4 bodyBodyContainer">
        <div className="borderTop"></div>
        <Row className="bodyHeaderText">Get To Know Flamefield</Row>
        <Row>
          <Col className="col-md-4 col-12  ">
            <img
              src={require("../../../assets/icons/know-flame.png")}
              className={"bodyImg"}
              alt={""}
            />
          </Col>
          <Col className="col-md-8 col-12 bodyTextContainer">
            <div className="sinceText">Since 1982</div>
            <div className="bodyBodyText">
              Flamefield is a specialist trade supplier of outdoor dining
              products which include break-resistant Melamine Tableware Acrylic
              & Polycarbonate drinkware and accessories. Our exclusive range of
              tableware designs are created for us by talented British designers
              who focus on developing stylish, innovative commercial designs for
              the global markets.
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
