import React from "react";
import Footer from "../../common/components/footer";
import ScrollToTop from "../../common/components/scroll-button";
import AllProductHeader from "../components/all-products/header";
import AllProductBody from "../components/all-products/body";
import RangeCategories, {
  RangeCategoriesForMobile,
} from "../components/range-body-components/range-categories";

export default function AllProducts() {
  return (
    <>
      <ScrollToTop />
      <AllProductHeader />
      <RangeCategoriesForMobile />
      <>
        <div className="container ">
          <div className="row  d-flex">
            <div className="col-xl-4 col-12 d-none d-lg-block">
              <RangeCategories />
            </div>
            <div className="col-xl-8 col-12">
              <AllProductBody />
            </div>
          </div>
        </div>
      </>
      <Footer />
    </>
  );
}
