import React, { useEffect, useState } from "react";
import { Row, Carousel, Col } from "react-bootstrap";
import { MdNavigateNext } from "react-icons/md";
import { HiArrowNarrowRight, HiArrowNarrowLeft } from "react-icons/hi";

import "./style.css";

export default function Range() {
  const [index, setIndex] = useState(0);
  const [img, setImg] = useState("malemine");

  const handleSelect = (selectedIndex: React.SetStateAction<number>) => {
    setIndex(selectedIndex);
  };

  const handleImg = (selectedImg: React.SetStateAction<string>) => {
    setImg(selectedImg);
  };

  useEffect(() => {
    if (index === 0) {
      setImg("malemine");
    }
    if (index === 1) {
      setImg("value");
    }
    if (index === 2) {
      setImg("poly");
    }
  }, [index]);

  const rangeArrMobile = [
    {
      id: 1,
      name: "New Melamine Range",
      imgPath: "malemine",
      path: "/malemine",
    },
    {
      id: 2,
      name: "Non-Slip Range",
      imgPath: "non-slip",
      path: "/non-slip",
    },
    {
      id: 3,
      name: "Premium Plus Range",
      imgPath: "premium-plus",
      path: "/pPlus",
    },
    {
      id: 4,
      name: "Premium Range",
      imgPath: "premium",
      path: "/premium",
    },
    {
      id: 5,
      name: "Value Range",
      imgPath: "value",
      path: "/value",
    },
    {
      id: 6,
      name: "Camper Smiles Range",
      imgPath: "camper",
      path: "/camper",
    },
    {
      id: 7,
      name: "Love Caravanning Range",
      imgPath: "love-caravan",
      path: "/love-caravanning",
    },
    {
      id: 8,
      name: "Children's Range",
      imgPath: "children",
      path: "/children",
    },
    {
      id: 9,
      name: "Polycarbonate Range",
      imgPath: "poly",
      path: "/polycarbonate",
    },
    {
      id: 10,
      name: "Acrylic Range",
      imgPath: "acrylic",
      path: "/acrylic",
    },
    {
      id: 11,
      name: "Accessories",
      imgPath: "accessories",
      path: "/others-accessories",
    },
  ];

  return (
    <>
      <div className="mw-100">
        <div className="borderTop"></div>
        <div className="headerText">Product Ranges</div>

        {/* Carousel For Web*/}
        <Carousel
          className="carouselWeb"
          activeIndex={index}
          onSelect={handleSelect}
          indicators={false}
          nextIcon={
            <div className="nextLabel">
              <HiArrowNarrowRight className="grNext" />
            </div>
          }
          prevIcon={
            <div className="prevLabel">
              <HiArrowNarrowLeft className="grNext" />
            </div>
          }
        >
          <Carousel.Item className="rangeDeneme">
            <img
              className="d-block w-100 imgRange "
              src={require(`../../../assets/icons/${img}.png`)}
              alt={""}
            />
            <Row className="rangeRow">
              <Col
                className="col-3 second"
                onMouseEnter={() => handleImg("malemine")}
              >
                <a href="/malemine" className="fullClick">
                  <div className="rangeTextContainer">
                    <div className="rangeName">New Melamine Range</div>

                    <div className="discoverText">
                      Discover <MdNavigateNext className="discoverIcon" />
                    </div>
                  </div>
                </a>
              </Col>
              <Col
                className="col-3 second"
                onMouseEnter={() => handleImg("non-slip")}
              >
                <a href="/non-slip" className="fullClick">
                  <div className="rangeTextContainer">
                    <div className="rangeName">Non-Slip Range</div>

                    <div className="discoverText">
                      Discover <MdNavigateNext className="discoverIcon" />
                    </div>
                  </div>{" "}
                </a>
              </Col>
              <Col
                className="col-3 second"
                onMouseEnter={() => handleImg("premium-plus")}
              >
                <a href="/pPlus" className="fullClick">
                  <div className="rangeTextContainer">
                    <div className="rangeName">Premium Plus Range</div>

                    <div className="discoverText">
                      Discover <MdNavigateNext className="discoverIcon" />
                    </div>
                  </div>
                </a>
              </Col>
              <Col
                className="col-3 second"
                onMouseEnter={() => handleImg("premium")}
              >
                <a href="/premium" className="fullClick">
                  <div className="rangeTextContainer">
                    <div className="rangeName">Premium Range</div>

                    <div className="discoverText">
                      Discover <MdNavigateNext className="discoverIcon" />
                    </div>
                  </div>
                </a>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item className="rangeDeneme">
            <img
              className="d-block w-100 imgRange "
              src={require(`../../../assets/icons/${img}.png`)}
              alt={""}
            />
            <Row className="rangeRow">
              <Col
                className="col-3 second"
                onMouseEnter={() => handleImg("value")}
              >
                <a href="/value" className="fullClick">
                  <div className="rangeTextContainer">
                    <div className="rangeNamee">Value Range</div>

                    <div className="discoverText">
                      Discover <MdNavigateNext className="discoverIcon" />
                    </div>
                  </div>{" "}
                </a>
              </Col>
              <Col
                className="col-3 second"
                onMouseEnter={() => handleImg("camper")}
              >
                <a href="/camper" className="fullClick">
                  <div className="rangeTextContainer">
                    <div className="rangeName">Camper Smiles Range</div>

                    <div className="discoverText">
                      Discover <MdNavigateNext className="discoverIcon" />
                    </div>
                  </div>
                </a>
              </Col>
              <Col
                className="col-3 second"
                onMouseEnter={() => handleImg("love-caravan")}
              >
                <a href="/love-caravanning" className="fullClick">
                  <div className="rangeTextContainer">
                    <div className="rangeName">Love Caravanning Range</div>

                    <div className="discoverText">
                      Discover <MdNavigateNext className="discoverIcon" />
                    </div>
                  </div>
                </a>
              </Col>
              <Col
                className="col-3 second"
                onMouseEnter={() => handleImg("children")}
              >
                <a href="/children" className="fullClick">
                  <div className="rangeTextContainer">
                    <div className="rangeName">Chilren's Range</div>

                    <div className="discoverText">
                      Discover <MdNavigateNext className="discoverIcon" />
                    </div>
                  </div>
                </a>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 imgRange"
              src={require(`../../../assets/icons/${img}.png`)}
              alt={""}
            />
            <Row className="rangeRow">
              <Col
                className="col-3 second"
                onMouseEnter={() => handleImg("poly")}
              >
                <a href="/polycarbonate" className="fullClick">
                  <div className="rangeTextContainer">
                    <div className="rangeNamee">Polycarbonate Range</div>

                    <div className="discoverText">
                      Discover <MdNavigateNext className="discoverIcon" />
                    </div>
                  </div>
                </a>
              </Col>
              <Col
                className="col-3 second"
                onMouseEnter={() => handleImg("acrylic")}
              >
                <a href="/acrylic" className="fullClick">
                  <div className="rangeTextContainer">
                    <div className="rangeNamee">Acrylic Range</div>

                    <div className="discoverText">
                      Discover <MdNavigateNext className="discoverIcon" />
                    </div>
                  </div>
                </a>
              </Col>
              <Col
                className="col-3 second"
                onMouseEnter={() => handleImg("accessories")}
              >
                {" "}
                <a href="/others-accessories" className="fullClick">
                  <div className="rangeTextContainer">
                    <div className="rangeNamee">Accessories</div>

                    <div className="discoverText">
                      Discover <MdNavigateNext className="discoverIcon" />
                    </div>
                  </div>
                </a>
              </Col>
            </Row>
          </Carousel.Item>
        </Carousel>

        {/* Carousel For Mobile */}
        <Carousel
          className="carouselMobile"
          activeIndex={index}
          onSelect={handleSelect}
          indicators={false}
          nextIcon={
            <div className="nextLabel">
              <HiArrowNarrowRight className="grNextMobile" />
            </div>
          }
          prevIcon={
            <div className="prevLabel">
              <HiArrowNarrowLeft className="grNextMobile" />
            </div>
          }
        >
          {rangeArrMobile.map((range) => (
            <Carousel.Item className="rangeDeneme" key={range.id}>
              <img
                className="d-block w-100 imgRangeMobile "
                src={require(`../../../assets/icons/${range.imgPath}.png`)}
                alt={""}
              />
              <Row className="rangeRowMobile">
                <Col className="col-12 secondMobile">
                  <div className="rangeTextContainerMobile">
                    <div className="rangeNameMobile">{range.name}</div>
                    <a href={range.path}>
                      <div className="discoverTextMobile">
                        Discover{" "}
                        <MdNavigateNext className="discoverIconMobile" />
                      </div>
                    </a>
                  </div>
                </Col>
              </Row>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </>
  );
}
