import React from "react";
import AllNonSlipBody from "../../all-products/AllNonSlipBody";

export default function NonSlipBody() {
  /*  const nonMelamine = {
    img: require("../../../../assets/icons/non-slip/thumbnail/non-melamine.jpg"),
    imgProduct: require("../../../../assets/icons/non-slip/products/non-melamine.jpg"),
    text: "A high quality anti slip dinnerware set in cool white and cool grey colour. Comes with a rim design, feeling and looking like real ceramic",
    amount: "12pc Set Cool Gray,",
    stock: "NSG0112",
  };
  const nonMelamine2 = {
    img: require("../../../../assets/icons/non-slip/thumbnail/non-melamine-2.jpg"),
    imgProduct: require("../../../../assets/icons/non-slip/products/non-melamine-2.jpg"),
    text: "A high quality anti slip dinnerware set in cool white and cool grey colour. Comes with a rim design, feeling and looking like real ceramic",
    amount: "12pc Set Cool White,",
    stock: "NSW0112",
    accImg: require("../../../../assets/icons/non-slip/thumbnail/non-melamine-acc.jpg"),
    accImgProduct: require("../../../../assets/icons/non-slip/products/non-melamine-acc.jpg"),
    accName: "Matching Accessories",
    accProductName: "Non-slip Mug",
    accAmount: "Cool White Mug",
    accStock: "NSW424",
  };
  const dunes = {
    img: require("../../../../assets/icons/non-slip/thumbnail/dunes.jpg"),
    imgProduct: require("../../../../assets/icons/non-slip/products/dunes.jpg"),
    text: "A premium quality dinner set in a dreamy abstract design and glossy finish. The set has an anti slip base which ensures a secure hold during transit.",
    amount: "12pc Set,",
    stock: "DUN0112",
    accImg: require("../../../../assets/icons/non-slip/thumbnail/dunes-acc.jpg"),
    accImgProduct: require("../../../../assets/icons/non-slip/products/dunes-acc.jpg"),
    accName: "Suggested Accessories",
    accProductName: "Moonstone Tumblers",
    accAmount: "",
    accStock: "",
  };
 */
  return (
    <>
      <AllNonSlipBody />
    </>
  );
}
