import React from "react";
import styles from "./style.module.css";

import TextField from "../../../common/components/text-field";
import { InfoField } from "../../../common/components/info-field";

import HeaderComponent from "../../../common/components/header";

export default function Header() {
  return (
    <div className={styles.container}>
      <HeaderComponent />

      <div className={styles.textContainer}>
        <TextField />
      </div>
      <div className={styles.infoContainer}>
        <InfoField />
      </div>
    </div>
  );
}
