import React from "react";
import RangeProduct from "../range-body-components/range-product";

import {
  smiles,
  smilesMug,
  backPack,
  camperSalad,
  platter,
  tumblers,
  cutlery,
  picnic,
  place,
} from "../camper/body";

export default function AllCamperBody() {
  return (
    <>
      <RangeProduct
        productImg={smiles.img}
        productImgProduct={smiles.imgProduct}
        productName={smiles.name}
        amount={smiles.amount}
        stockCode={smiles.stock}
        productAciklma={smiles.text}
      />

      <RangeProduct
        productImg={smilesMug.img}
        productImgProduct={smilesMug.imgProduct}
        productName={smilesMug.name}
        amount={smilesMug.amount}
        stockCode={smilesMug.stock}
      />

      <RangeProduct
        productImg={backPack.img}
        productImgProduct={backPack.imgProduct}
        productName={backPack.name}
        amount={backPack.amount}
        stockCode={backPack.stock}
      />

      <RangeProduct
        productImg={camperSalad.img}
        productImgProduct={camperSalad.imgProduct}
        productName={camperSalad.name}
        amount={camperSalad.amount}
        stockCode={camperSalad.stock}
        productAciklma={camperSalad.text}
      />

      <RangeProduct
        productImg={platter.img}
        productImgProduct={platter.imgProduct}
        productName={platter.name}
        amount={platter.amount}
        stockCode={platter.stock}
      />

      <RangeProduct
        productImg={tumblers.img}
        productImgProduct={tumblers.imgProduct}
        productName={smiles.name}
        amount={tumblers.amount}
        stockCode={tumblers.stock}
        productAciklma={tumblers.text}
      />

      <RangeProduct
        productImg={cutlery.img}
        productImgProduct={cutlery.imgProduct}
        productName={cutlery.name}
        amount={cutlery.amount}
        stockCode={cutlery.stock}
        productAciklma={cutlery.text}
      />

      <RangeProduct
        productImg={picnic.img}
        productImgProduct={picnic.imgProduct}
        productName={picnic.name}
        amount={picnic.amount}
        stockCode={picnic.stock}
        productAciklma={picnic.text}
      />

      <RangeProduct
        productImg={place.img}
        productImgProduct={place.imgProduct}
        productName={place.name}
        amount={place.amount}
        stockCode={place.stock}
        productAciklma={place.text}
      />
    </>
  );
}
