import React from "react";
import HeaderComponent from "../../../common/components/header";
import "./style.css";

export default function ContactHeader() {
  return (
    <div className="contactContainer">
      <HeaderComponent />
      <div className="contactTextContainer">
        <p className="contactText"></p>
      </div>
    </div>
  );
}
