import React from "react";
import Footer from "../../common/components/footer";
import PremiumPlusHeader from "../components/premium-plus/header";
import PremiumPlusBody from "../components/premium-plus/body";
import ScrollToTop from "../../common/components/scroll-button";
import RangeCategories, {
  RangeCategoriesForMobile,
} from "../components/range-body-components/range-categories";

export default function PremiumPlus() {
  return (
    <>
      <ScrollToTop />
      <PremiumPlusHeader />
      <RangeCategoriesForMobile />
      <>
        <div className="container ">
          <div className="row  d-flex">
            <div className="col-xl-4 col-12 d-none d-lg-block">
              <RangeCategories />
            </div>
            <div className="col-xl-8 col-12">
              <PremiumPlusBody />
            </div>
          </div>
        </div>
      </>
      <Footer />
    </>
  );
}
