import React from "react";
import AllCaravanBody from "../../all-products/AllCaravanBody";

export const caravan = {
  name: "Love Caravanning",
  img: require("../../../../assets/icons/caravan/thumbnail/love-caravan.jpg"),
  imgProduct: require("../../../../assets/icons/caravan/products/love-caravan.jpg"),
  text: "The Love Caravanning series has been inspired by the distinctive shape of the 1930’s original vintage caravan. This premium+ set comes in a 16lt Cooler Bag with matching dinner set",
  amount: "12pc Set,",
  stock: "LC0113",
};
export const caravanMug = {
  name: "Love Caravanning Mugs",
  img: require("../../../../assets/icons/caravan/thumbnail/love-caravan-acc.jpg"),
  imgProduct: require("../../../../assets/icons/caravan/products/love-caravan-acc.jpg"),
  amount: "4pc,",
  stock: "LC424",
};
export const salad = {
  name: "Salad Bowl",
  img: require("../../../../assets/icons/caravan/thumbnail/love-caravan2-acc.jpg"),
  imgProduct: require("../../../../assets/icons/caravan/products/love-caravan2-acc.jpg"),
  amount: "24pc,",
  text: "Approx. 9.8in dia salad bowl",
  stock: "LC311",
};
export const lineSalad = {
  name: "Linea Salad Bowl",
  img: require("../../../../assets/icons/caravan/thumbnail/love-caravan3-acc.jpg"),
  imgProduct: require("../../../../assets/icons/caravan/products/love-caravan3-acc.jpg"),
  amount: "24pc,",
  text: "Approx. 13.7in rectangular platter",
  stock: "LC354",
};
export const shortTumblers = {
  name: "Short Tumblers",
  img: require("../../../../assets/icons/caravan/thumbnail/short-tumblers.jpg"),
  imgProduct: require("../../../../assets/icons/caravan/products/short-tumblers.jpg"),
  text: "Short printed tumblers",
  amount: "2pc,",
  stock: "LC693",
};
export const tallTumblers = {
  name: "Tall Tumblers",
  img: require("../../../../assets/icons/caravan/thumbnail/tall-tumblers.jpg"),
  imgProduct: require("../../../../assets/icons/caravan/products/tall-tumblers.jpg"),
  text: "Tall printed tumblers",
  amount: "2pc,",
  stock: "LC694",
};

export default function CaravanningBody() {
  return (
    <>
      <AllCaravanBody />
    </>
  );
}
