import React from "react";

import AllNewMalemineBody from "../../all-products/NewMalemineBody";

export default function NewMalemineBody() {
  /*  const tiffany = {
    img: require("../../../../assets/icons/new-malemine/thumbnail/tiffany.jpg"),
    imgProduct: require("../../../../assets/icons/new-malemine/products/tiffany.jpg"),
    text: "A premium quality dinner set in a vintage style design and glossy finish.",
    amount: "12pc Set,",
    stock: "TIF0112",
    accImg: require("../../../../assets/icons/new-malemine/thumbnail/tiffany-acce.png"),
    accImgProduct: require("../../../../assets/icons/new-malemine/products/tiffany-acce.png"),
    accName: "Matching Accessories",
    accProductName: "Stackable Mugs",
    accAmount: "4 pc,",
    accStock: "TIF423",
  };
  const royaleEcru = {
    img: require("../../../../assets/icons/new-malemine/thumbnail/royale.jpg"),
    imgProduct: require("../../../../assets/icons/new-malemine/products/royale.jpg"),
    text: "A sophisticated design in white with a beige edge",
    amount: "12pc Set,",
    stock: "RYE0112",
    accImg: require("../../../../assets/icons/new-malemine/thumbnail/royale-acc.jpg"),
    accImgProduct: require("../../../../assets/icons/new-malemine/products/royale-acc.jpg"),
    accName: "Suggested Accessories",
    accProductName: "Clear Acrylic Wine Goblet",
    accAmount: "",
    accStock: "",
  };
  const royaleOnyx = {
    img: require("../../../../assets/icons/new-malemine/thumbnail/royale-onyx.jpg"),
    imgProduct: require("../../../../assets/icons/new-malemine/products/royale-onyx.jpg"),
    text: "A sophisticated design in white with a black edge",
    amount: "12pc Set,",
    stock: "RYX0112",
    accImg: require("../../../../assets/icons/new-malemine/thumbnail/royale-onyx-acc.jpg"),
    accImgProduct: require("../../../../assets/icons/new-malemine/products/royale-onyx-acc.jpg"),
    accName: "Suggested Accessories",
    accProductName: "Black Cutlery",
    accAmount: "",
    accStock: "",
  };
  const pebble = {
    img: require("../../../../assets/icons/new-malemine/thumbnail/pebble.jpg"),
    imgProduct: require("../../../../assets/icons/new-malemine/products/pebble.jpg"),
    text: "A premium quality dinner set with a subtle pattern and colour.",
    amount: "12pc Set,",
    stock: "PB0112",
  }; */

  return (
    <>
      <AllNewMalemineBody />
    </>
  );
}
