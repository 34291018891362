import React from "react";

import RangeProduct from "../../range-body-components/range-product";

export default function AcrylicBody() {
  const products = [
    {
      img: require("../../../../assets/icons/acrylic/thumbnail/moonstone.jpg"),
      imgProduct: require("../../../../assets/icons/acrylic/products/moonstone.jpg"),
      name: "Moonstone Short Tumblers",
      text: "Short tumbler set in 4 beautiful colors.",
      amount: "4pk",
      stockCode: "SP1009",
    },
    {
      img: require("../../../../assets/icons/acrylic/thumbnail/moonstone-tall.jpg"),
      imgProduct: require("../../../../assets/icons/acrylic/products/moonstone-tall.jpg"),
      name: "Moonstone Tall Tumblers",
      text: "Tall tumbler set in 4 beautiful colors.",
      amount: "4pk",
      stockCode: "SP1010",
    },
    {
      img: require("../../../../assets/icons/acrylic/thumbnail/product1.jpg"),
      imgProduct: require("../../../../assets/icons/acrylic/products/product1.jpg"),
      name: "Crystal Wine",
      text: "",
      amount: "4pk",
      stockCode: "SP2018",
    },
    {
      img: require("../../../../assets/icons/acrylic/thumbnail/product2.jpg"),
      imgProduct: require("../../../../assets/icons/acrylic/products/product2.jpg"),
      name: "Crystal Short",
      text: "",
      amount: "4pk",
      stockCode: "SP2125",
    },
    {
      img: require("../../../../assets/icons/acrylic/thumbnail/product3.jpg"),
      imgProduct: require("../../../../assets/icons/acrylic/products/product3.jpg"),
      name: "Crystal Tall",
      text: "",
      amount: "4pk",
      stockCode: "SP2017",
    },
    {
      img: require("../../../../assets/icons/acrylic/thumbnail/product4.jpg"),
      imgProduct: require("../../../../assets/icons/acrylic/products/product4.jpg"),
      name: "Infuser Bottle Set",
      text: "Top of bottle unscrews to allow fruit or mixers to be added. Comes with 4 matching glasses.",
      amount: "4pk",
      stockCode: "SP2136",
    },
    {
      img: require("../../../../assets/icons/acrylic/thumbnail/product5.jpg"),
      imgProduct: require("../../../../assets/icons/acrylic/products/product5.jpg"),
      name: "Bella Goblet",
      text: "",
      amount: "4pk",
      stockCode: "T0186(cm)",
    },
    {
      img: require("../../../../assets/icons/acrylic/thumbnail/product6.jpg"),
      imgProduct: require("../../../../assets/icons/acrylic/products/product6.jpg"),
      name: "Slim Jim Short",
      text: "",
      amount: "4pk",
      stockCode: "400(cm)",
    },
    {
      img: require("../../../../assets/icons/acrylic/thumbnail/product7.jpg"),
      imgProduct: require("../../../../assets/icons/acrylic/products/product7.jpg"),
      name: "Wine Goblet",
      text: "",
      amount: "4pk",
      stockCode: "T0151(cm)",
    },
    {
      img: require("../../../../assets/icons/acrylic/thumbnail/product8.jpg"),
      imgProduct: require("../../../../assets/icons/acrylic/products/product8.jpg"),
      name: "Juice Glass",
      text: "",
      amount: "4pk",
      stockCode: "AC300(cm)",
    },
    {
      img: require("../../../../assets/icons/acrylic/thumbnail/product9.jpg"),
      imgProduct: require("../../../../assets/icons/acrylic/products/product9.jpg"),
      name: "Stacking Glasses",
      text: "",
      amount: "4pk",
      stockCode: "1563(cm)",
    },
    {
      img: require("../../../../assets/icons/acrylic/thumbnail/product10.jpg"),
      imgProduct: require("../../../../assets/icons/acrylic/products/product10.jpg"),
      name: "Bella Goblet",
      text: "",
      amount: "4pk",
      stockCode: "T0186(sgm)",
    },
    {
      img: require("../../../../assets/icons/acrylic/thumbnail/product11.jpg"),
      imgProduct: require("../../../../assets/icons/acrylic/products/product11.jpg"),
      name: "Slim Jim Short",
      text: "",
      amount: "4pk",
      stockCode: "400(sgm)",
    },
    {
      img: require("../../../../assets/icons/acrylic/thumbnail/product12.jpg"),
      imgProduct: require("../../../../assets/icons/acrylic/products/product12.jpg"),
      name: "Wine Goblet",
      text: "",
      amount: "4pk",
      stockCode: "T0151(sgm)",
    },
    {
      img: require("../../../../assets/icons/acrylic/thumbnail/product13.jpg"),
      imgProduct: require("../../../../assets/icons/acrylic/products/product13.jpg"),
      name: "Juice Glass",
      text: "",
      amount: "4pk",
      stockCode: "AC300(sgm)",
    },
    {
      img: require("../../../../assets/icons/acrylic/thumbnail/product14.jpg"),
      imgProduct: require("../../../../assets/icons/acrylic/products/product14.jpg"),
      name: "Stacking Glasses",
      text: "",
      amount: "4pk",
      stockCode: "1563(sgm)",
    },
    {
      img: require("../../../../assets/icons/acrylic/thumbnail/product15.jpg"),
      imgProduct: require("../../../../assets/icons/acrylic/products/product15.jpg"),
      name: "Bella Goblet",
      text: "",
      amount: "4pk",
      stockCode: "T0186M",
    },
    {
      img: require("../../../../assets/icons/acrylic/thumbnail/product16.jpg"),
      imgProduct: require("../../../../assets/icons/acrylic/products/product16.jpg"),
      name: "Slim Jim Short",
      text: "",
      amount: "4pk",
      stockCode: "400M",
    },
    {
      img: require("../../../../assets/icons/acrylic/thumbnail/product17.jpg"),
      imgProduct: require("../../../../assets/icons/acrylic/products/product17.jpg"),
      name: "Wine Goblet",
      text: "",
      amount: "4pk",
      stockCode: "T0151M",
    },
    {
      img: require("../../../../assets/icons/acrylic/thumbnail/product18.jpg"),
      imgProduct: require("../../../../assets/icons/acrylic/products/product18.jpg"),
      name: "Juice Glass",
      text: "",
      amount: "4pk",
      stockCode: "AC300M",
    },
    {
      img: require("../../../../assets/icons/acrylic/thumbnail/product19.jpg"),
      imgProduct: require("../../../../assets/icons/acrylic/products/product19.jpg"),
      name: "Soda Glass",
      text: "",
      amount: "4pk",
      stockCode: "T0346M",
    },
    {
      img: require("../../../../assets/icons/acrylic/thumbnail/product20.jpg"),
      imgProduct: require("../../../../assets/icons/acrylic/products/product20.jpg"),
      name: "Prosecco/ Champagne Glasses",
      text: "",
      amount: "4pk",
      stockCode: "SP0996M",
    },
    {
      img: require("../../../../assets/icons/acrylic/thumbnail/product21.jpg"),
      imgProduct: require("../../../../assets/icons/acrylic/products/product21.jpg"),
      name: "Midi Glass",
      text: "",
      amount: "4pk",
      stockCode: "PE001M",
    },
    {
      img: require("../../../../assets/icons/acrylic/thumbnail/product22.jpg"),
      imgProduct: require("../../../../assets/icons/acrylic/products/product22.jpg"),
      name: "Shot Glass",
      text: "",
      amount: "4pk",
      stockCode: "T0344M",
    },
    {
      img: require("../../../../assets/icons/acrylic/thumbnail/product23.jpg"),
      imgProduct: require("../../../../assets/icons/acrylic/products/product23.jpg"),
      name: "Stacking Wine Goblets",
      text: "",
      amount: "4pk",
      stockCode: "SP1034",
    },
    {
      img: require("../../../../assets/icons/acrylic/thumbnail/product24.jpg"),
      imgProduct: require("../../../../assets/icons/acrylic/products/product24.jpg"),
      name: "Children's Glasses",
      text: "",
      amount: "4pk",
      stockCode: "AC300AP",
    },
    {
      img: require("../../../../assets/icons/acrylic/thumbnail/product25.jpg"),
      imgProduct: require("../../../../assets/icons/acrylic/products/product25.jpg"),
      name: "Short Tumbler Blue",
      text: "",
      amount: "4pk",
      stockCode: "RE2011B",
    },
    {
      img: require("../../../../assets/icons/acrylic/thumbnail/product25.jpg"),
      imgProduct: require("../../../../assets/icons/acrylic/products/product25.jpg"),
      name: "Tall Tumbler Blue",
      text: "",
      amount: "4pk",
      stockCode: "RE2012B",
    },
    {
      img: require("../../../../assets/icons/acrylic/thumbnail/product27.jpg"),
      imgProduct: require("../../../../assets/icons/acrylic/products/product27.jpg"),
      name: "Heavy Base Wine Goblet Blue",
      text: "",
      amount: "4pk",
      stockCode: "HBG612B",
    },
    {
      img: require("../../../../assets/icons/acrylic/thumbnail/product28.jpg"),
      imgProduct: require("../../../../assets/icons/acrylic/products/product28.jpg"),
      name: "Short Tumbler Aqua",
      text: "",
      amount: "4pk",
      stockCode: "RE2011A",
    },
    {
      img: require("../../../../assets/icons/acrylic/thumbnail/product28.jpg"),
      imgProduct: require("../../../../assets/icons/acrylic/products/product28.jpg"),
      name: "Tall Tumbler Aqua",
      text: "",
      amount: "4pk",
      stockCode: "RE2012A",
    },
    {
      img: require("../../../../assets/icons/acrylic/thumbnail/product30.jpg"),
      imgProduct: require("../../../../assets/icons/acrylic/products/product30.jpg"),
      name: "Short Tumbler Aqua",
      text: "",
      amount: "4pk",
      stockCode: "SF501A",
    },
    {
      img: require("../../../../assets/icons/acrylic/thumbnail/product30.jpg"),
      imgProduct: require("../../../../assets/icons/acrylic/products/product30.jpg"),
      name: "Tall Tumbler Aqua",
      text: "",
      amount: "4pk",
      stockCode: "SF502A",
    },
    {
      img: require("../../../../assets/icons/acrylic/thumbnail/product32.jpg"),
      imgProduct: require("../../../../assets/icons/acrylic/products/product32.jpg"),
      name: "Short Tumbler Grey",
      text: "",
      amount: "4pk",
      stockCode: "SF501G",
    },
    {
      img: require("../../../../assets/icons/acrylic/thumbnail/product32.jpg"),
      imgProduct: require("../../../../assets/icons/acrylic/products/product32.jpg"),
      name: "Tall Tumbler Gray",
      text: "",
      amount: "4pk",
      stockCode: "SF502G",
    },
    {
      img: require("../../../../assets/icons/acrylic/thumbnail/product34.jpg"),
      imgProduct: require("../../../../assets/icons/acrylic/products/product34.jpg"),
      name: "Stacking Wine Goblet",
      text: "",
      amount: "2pk",
      stockCode: "SP1843",
    },
    {
      img: require("../../../../assets/icons/acrylic/thumbnail/product35.jpg"),
      imgProduct: require("../../../../assets/icons/acrylic/products/product35.jpg"),
      name: "PilsnerGlass",
      text: "",
      amount: "24pc",
      stockCode: "533",
    },
    {
      img: require("../../../../assets/icons/acrylic/thumbnail/product36.jpg"),
      imgProduct: require("../../../../assets/icons/acrylic/products/product36.jpg"),
      name: "Tall Beer Glass",
      text: "",
      amount: "24pc",
      stockCode: "Y012",
    },
  ];

  return (
    <>
      {products.map((product) => (
        <RangeProduct
          productImg={product.img}
          productImgProduct={product.imgProduct}
          productName={product.name}
          amount={product.amount}
          stockCode={product.stockCode}
          productAciklma={product.text}
        />
      ))}
    </>
  );
}
