import React from "react";
import { Dropdown } from "react-bootstrap";
import "./style.css";

export function RangeCategoriesForMobile() {
  return (
    <>
      <div className=" d-lg-none" style={{ marginTop: "20px" }}>
        <Dropdown className="categoriesDropdown col-12">
          <Dropdown.Toggle
            variant="secondary"
            id="dropdown-basic"
            className="col-10"
          >
            Categories
          </Dropdown.Toggle>

          <Dropdown.Menu className="col-10">
            <Dropdown.Item href="#" className="mobileHeaderText">
              • Melamine Range
            </Dropdown.Item>
            <Dropdown.Item href="/malemine" className="mobileElementText">
              ◦ New Melamine Range
            </Dropdown.Item>
            <Dropdown.Item href="/non-slip" className="mobileElementText">
              ◦ Non-Slip Range
            </Dropdown.Item>
            <Dropdown.Item href="/pPlus" className="mobileElementText">
              ◦ Premium Plus Range
            </Dropdown.Item>
            <Dropdown.Item href="/premium" className="mobileElementText">
              ◦ Premium Range
            </Dropdown.Item>
            <Dropdown.Item href="/value" className="mobileElementText">
              ◦ Value Range
            </Dropdown.Item>
            <Dropdown.Item href="/camper" className="mobileElementText">
              ◦ Camper Smiles Range
            </Dropdown.Item>
            <Dropdown.Item
              href="/love-caravanning"
              className="mobileElementText"
            >
              ◦ Love Caravanning Range
            </Dropdown.Item>
            <Dropdown.Item href="/children" className="mobileElementText">
              ◦ Children's Range
            </Dropdown.Item>
            <Dropdown.Item href="/polycarbonate" className="mobileHeaderText">
              • Polycarbonate Range
            </Dropdown.Item>
            <Dropdown.Item href="#" className="mobileHeaderText">
              • Acrylic Range
            </Dropdown.Item>
            <Dropdown.Item href="/acrylic" className="mobileElementText">
              ◦ New Acrylic Range
            </Dropdown.Item>
            <Dropdown.Item
              href="/love-caravanning"
              className="mobileElementText"
            >
              ◦ Love Caravanning Range
            </Dropdown.Item>
            <Dropdown.Item href="/children" className="mobileElementText">
              ◦ Children's Range
            </Dropdown.Item>
            <Dropdown.Item href="#" className="mobileHeaderText">
              • Accessories
            </Dropdown.Item>
            <Dropdown.Item href="/camper" className="mobileElementText">
              ◦ Camper Smiles Range
            </Dropdown.Item>
            <Dropdown.Item
              href="/love-caravanning"
              className="mobileElementText"
            >
              ◦ Love Caravanning Range
            </Dropdown.Item>
            <Dropdown.Item href="/children" className="mobileElementText">
              ◦ Children's Range
            </Dropdown.Item>
            <Dropdown.Item
              href="/others-accessories"
              className="mobileElementText"
            >
              ◦ Others
            </Dropdown.Item>
            <Dropdown.Item href="/all-products" className="mobileHeaderText">
              • All Products
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
}

export default function RangeCategories() {
  return (
    <>
      <div className="categoryContainer">
        <h3>Categories</h3>
        <p className="categoriesHeaderText">• Melamine Range</p>
        <div className="subElementContainer">
          <div className="subElementTextContainer">
            <a href="/malemine" className="subElementText">
              ◦ New Melamine Range
            </a>
          </div>
          <div className="subElementTextContainer">
            <a href="/non-slip" className="subElementText">
              ◦ Non-Slip Range
            </a>
          </div>
          <div className="subElementTextContainer">
            <a href="/pPlus" className="subElementText">
              ◦ Premium Plus Range
            </a>
          </div>
          <div className="subElementTextContainer">
            <a href="/premium" className="subElementText">
              ◦ Premium Range
            </a>
          </div>
          <div className="subElementTextContainer">
            <a href="/value" className="subElementText">
              ◦ Value Range
            </a>
          </div>
          <div className="subElementTextContainer">
            <a href="/camper" className="subElementText">
              ◦ Camper Smiles Range
            </a>
          </div>
          <div className="subElementTextContainer">
            <a href="/love-caravanning" className="subElementText">
              ◦ Love Caravanning Range
            </a>
          </div>
          <div className="subElementTextContainer">
            <a href="/children" className="subElementText">
              ◦ Children's Range
            </a>
          </div>
        </div>
        <a href="/polycarbonate" className="categoriesHeaderText">
          • Polycarbonate Range
        </a>
        <p className="categoriesHeaderText">• Acrylic Range</p>
        <div className="subElementContainer">
          <div className="subElementTextContainer">
            <a href="/acrylic" className="subElementText">
              ◦ New Acrylic Range
            </a>
          </div>

          <div className="subElementTextContainer">
            <a href="/love-caravanning" className="subElementText">
              ◦ Love Caravanning Range
            </a>
          </div>
          <div className="subElementTextContainer">
            <a href="/children" className="subElementText">
              ◦ Children's Range
            </a>
          </div>
        </div>
        <p className="categoriesHeaderText">• Accessories</p>
        <div className="subElementContainer">
          <div className="subElementTextContainer">
            <a href="/camper" className="subElementText">
              ◦ Camper Smiles Range
            </a>
          </div>

          <div className="subElementTextContainer">
            <a href="/love-caravanning" className="subElementText">
              ◦ Love Caravanning Range
            </a>
          </div>
          <div className="subElementTextContainer">
            <a href="/children" className="subElementText">
              ◦ Children's Range
            </a>
          </div>
          <div className="subElementTextContainer">
            <a href="/others-accessories" className="subElementText">
              ◦ Others
            </a>
          </div>
        </div>
        <a href="/all-products" className="categoriesHeaderText">
          • All Products
        </a>
      </div>
    </>
  );
}
