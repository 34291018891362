import React from "react";

import RangeProduct from "../../range-body-components/range-product";

export default function OthersBody() {
  const products = [
    {
      img: require("../../../../assets/icons/others/thumbnail/lamp.jpg"),
      imgProduct: require("../../../../assets/icons/others/products/lamp.jpg"),
      name: "Bluetooth Flame Lamp",
      text: "Flame Lamp with Wireless Bluetooth Speaker. High quality LED light, 60 LEDs forming a realistic Flame.",
      amount: "8pc",
      stockCode: "LT-A6C-B",
    },
    {
      img: require("../../../../assets/icons/others/thumbnail/product1.jpg"),
      imgProduct: require("../../../../assets/icons/others/products/product1.jpg"),
      name: "3PC Container Set",
      text: "",
      amount: "12pc",
      stockCode: "WH-543",
    },
    {
      img: require("../../../../assets/icons/others/thumbnail/product2.jpg"),
      imgProduct: require("../../../../assets/icons/others/products/product2.jpg"),
      name: "Black Cutlery Set",
      text: "",
      amount: "12pc",
      stockCode: "CS235-BLK",
    },
    {
      img: require("../../../../assets/icons/others/thumbnail/product3.jpg"),
      imgProduct: require("../../../../assets/icons/others/products/product3.jpg"),
      name: "Grey Cutlery Set in Holder",
      text: "",
      amount: "12pc",
      stockCode: "SP2017",
    },
    {
      img: require("../../../../assets/icons/others/thumbnail/product4.jpg"),
      imgProduct: require("../../../../assets/icons/others/products/product4.jpg"),
      name: "Aqua Cutlery Set in Holder",
      text: "",
      amount: "12pc",
      stockCode: "CS2039A",
    },
    {
      img: require("../../../../assets/icons/others/thumbnail/product5.jpg"),
      imgProduct: require("../../../../assets/icons/others/products/product5.jpg"),
      name: "Salt & Pepper Mill Black/White",
      text: "",
      amount: "24pc",
      stockCode: "SNP619 (BLK), SNP619 (WHT)",
    },
    {
      img: require("../../../../assets/icons/others/thumbnail/product6.jpg"),
      imgProduct: require("../../../../assets/icons/others/products/product6.jpg"),
      name: "Oil Vinegar Sprayer Black/White",
      text: "",
      amount: "24pc",
      stockCode: "SNP619 (WHT),SNP619 (BLK)",
    },
    {
      img: require("../../../../assets/icons/others/thumbnail/product7.jpg"),
      imgProduct: require("../../../../assets/icons/others/products/product7.jpg"),
      name: "Coco Camper Caravan Mat",
      text: "",
      amount: "15pk",
      stockCode: "CO040",
    },
    {
      img: require("../../../../assets/icons/others/thumbnail/product8.jpg"),
      imgProduct: require("../../../../assets/icons/others/products/product8.jpg"),
      name: "Coco Caravan Mat",
      text: "",
      amount: "15pc",
      stockCode: "CO045",
    },
    {
      img: require("../../../../assets/icons/others/thumbnail/product9.jpg"),
      imgProduct: require("../../../../assets/icons/others/products/product9.jpg"),
      name: "Whistling Kettle Red",
      text: "",
      amount: "12pc",
      stockCode: "WHK1",
      className: "redImg",
    },
    {
      img: require("../../../../assets/icons/others/thumbnail/product10.jpg"),
      imgProduct: require("../../../../assets/icons/others/products/product10.jpg"),
      name: "Whistling Kettle Blue",
      text: "",
      amount: "12pc",
      stockCode: "WHK1",
    },
    {
      img: require("../../../../assets/icons/others/thumbnail/product11.jpg"),
      imgProduct: require("../../../../assets/icons/others/products/product11.jpg"),
      name: "Whistling Kettle Black",
      text: "",
      amount: "12pc",
      stockCode: "WHK1",
    },
    {
      img: require("../../../../assets/icons/others/thumbnail/product12.jpg"),
      imgProduct: require("../../../../assets/icons/others/products/product12.jpg"),
      name: "Whistling Kettle Green",
      text: "",
      amount: "12pc",
      stockCode: "WHK1",
      className: "greenCream",
    },
    {
      img: require("../../../../assets/icons/others/thumbnail/product13.jpg"),
      imgProduct: require("../../../../assets/icons/others/products/product13.jpg"),
      name: "Whistling Kettle Cream",
      text: "",
      amount: "12pc",
      stockCode: "WHK1",
      className: "greenCream",
    },
  ];

  return (
    <>
      {products.map((product) => (
        <RangeProduct
          productImg={product.img}
          productImgProduct={product.imgProduct}
          productName={product.name}
          amount={product.amount}
          stockCode={product.stockCode}
          productAciklma={product.text}
          className={product.className}
        />
      ))}
    </>
  );
}
