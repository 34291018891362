import React from "react";
import Footer from "../common/components/footer";
import FlamefieldHeader from "../flamefield/components/header";
import BodyComponent from "../common/components/body-component";
import ScrollToTop from "../common/components/scroll-button";

export default function Flamefield() {
  return (
    <>
      <ScrollToTop />
      <FlamefieldHeader />
      <BodyComponent />
      <Footer />
    </>
  );
}
