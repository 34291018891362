import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./style.css";

export default function BodyComponent() {
  return (
    <div className="marginTop">
      <Container className="componentBodyContainer">
        <div className="borderTop"></div>
        <Row className="componentBodyRow d-flex justify-content-center">
          <Col className="col-lg-4 col-12 ">
            <div className="componentBodyImgContainer">
              <img
                alt=""
                src={require("../../../assets/icons/collection-2.jpg")}
                className="componentBodyImg imgRight"
              />
            </div>
          </Col>
          <Col className="col-lg-5 col-12 bodyTextCol">
            <div className="txtLeft">
              <div className="componentHeaderText">Non-Slip Range</div>
              <p className="componentBodyText">
                A high quality anti slip dinnerware set in cool white and cool
                grey colour. Comes with a rim design, feeling and looking like
                real ceramic.
              </p>
              <div className="bodyBtnContainer">
                <a href="#" className="bodyBtnText">
                  Discover
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="componentBodyContainer">
        <Row className="componentBodyRow d-flex justify-content-center">
          <Col className="col-lg-4 col-12 bodyTextColLeft">
            <div className="textRight">
              <div className="componentHeaderText">Non-Slip Range</div>
              <p className="componentBodyText">
                A premium quality dinner set in a dreamy abstract design and
                glossy finish. The set has an anti slip base which ensures a
                secure hold during transit
              </p>
              <div className="bodyBtnContainer">
                <a href="#" className="bodyBtnText">
                  Discover
                </a>
              </div>
            </div>
          </Col>
          <Col className="col-lg-5 col-12 ">
            <div className="componentBodyImgContainer">
              <img
                alt=""
                src={require("../../../assets/icons/collection-3.jpg")}
                className="componentBodyImg imgLeft"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
