import React from "react";
import HeaderComponent from "../../../../../common/components/header";
import "../../../../style/header-style.css";

export default function MapHeader() {
  return (
    <div className="footerLinkHeadContainer mapContainer">
      <HeaderComponent />
      <div className="footerLinkHeadTextContainer">
        <p className="footerLinkHeadText">Site Map</p>
      </div>
    </div>
  );
}
